import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHome,
  faRightFromBracket,
  faKey,
  faUserCircle,
  faChevronDown,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import { serverUrl } from "../../App";

const Header = () => {
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};

  const idType = state.device
    ? "deviceTokenId"
    : state.bu
    ? "buMasterId"
    : state.makeModelData
    ? "makeModelMasterId"
    : undefined;

  const {
    device = null,
    bu = null,
    makeModelData = null,
    vehicle = null,
    pageType = null,
  } = state;

  const queryParams = new URLSearchParams(location.search);
  const formType = queryParams.get("form"); // Extract formType from queryParams
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showViewProfileModal, setShowViewProfileModal] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
  });
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [showPasswordSuccessModal, setShowPasswordSuccessModal] =
    useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [passwordObject, setPasswordObject] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [passwordErrors, setPasswordErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [userDetails, setUserDetails] = useState([]);

  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const [isUpdateEnable, setIsUpdateEnable] = useState(false);
  const [ouExpiryDate, setOuExpiryDate] = useState(null);
  const [showOuExpiryText, setShowOuExpiryText] = useState(false);
  const [isFromSuperAdmin, setIsFromSuperAdmin] = useState(false);

  //Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  useEffect(() => {
    fetchUserList();
  }, [navigate]);

  // useEffect(() => {
  //   debugger;
  //   const fetchData = async () => {
  //     try {
  //       const storedUserData = sessionStorage.getItem("user");
  //       console.log(storedUserData);

  //       if (storedUserData) {
  //         const parsedUserData = JSON.parse(storedUserData);

  //         if (
  //           parsedUserData.entity &&
  //           parsedUserData.entity.roleOuObjs.length > 0
  //         ) {
  //           const ouMasterId =
  //             parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
  //           setUserMasterObject({
  //             firstName: parsedUserData.entity.firstName,
  //             lastName: parsedUserData.entity.lastName,
  //             designation: parsedUserData.entity.designation,
  //             username: parsedUserData.entity.username,
  //             userMasterId: parsedUserData.entity.id,
  //             ouMasterId,
  //           });
  //         } else {
  //           toastWarning("User data not available.", 3000);
  //           return;
  //         }
  //       } else {
  //         navigate(`/`);
  //       }
  //     } catch (error) {
  //       console.error("Error in fetchData:", error);
  //     }
  //   };

  //   fetchData();
  // }, [navigate]);

  useEffect(() => {
    debugger;
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);

          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            if (
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.expiryDate != null
            ) {
              setOuExpiryDate(
                parsedUserData.entity.roleOuObjs[0].ouMasterObj.expiryDate
              );

              // Calculate the difference between the expiration date and today's date
              const today = new Date();
              const expiryDate = new Date(
                parsedUserData.entity.roleOuObjs[0].ouMasterObj.expiryDate
              );

              // Get the difference in time (milliseconds) and convert it to days
              const timeDiff = expiryDate.getTime() - today.getTime();
              const daysDiff = timeDiff / (1000 * 3600 * 24);

              if (daysDiff <= 30 && daysDiff > 0) {
                setShowOuExpiryText(true);
              }
            }

            if (
              parsedUserData.entity.roleOuObjs[0].roleMasterobj.id ===
              "rbeb93bf-411c-43f6-8e3f-4bcf8c832fc702"
            ) {
              setIsFromSuperAdmin(true);
              // console.log("true");
            } else {
              setIsFromSuperAdmin(false);
              // console.log("false");
            }
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    if (showChangePasswordModal) {
      // Reset password fields
      setPasswordObject({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      });
      setPasswordErrors({}); // Reset password errors as well
    }
  }, [showChangePasswordModal]);

  const getTitle = (pathname, idType, device, bu, makeModelData) => {
    // Check if the idType is known
    let determinedIdType;
    const [, id, pathIdType] = pathname.split("/");
    if (
      pathIdType === "deviceTokenId" ||
      pathIdType === "buMasterId" ||
      pathIdType === "makeModelMasterId"
    ) {
      determinedIdType = pathIdType;
    } else {
      // Default to idType if no specific idType is found in the pathname
      determinedIdType = idType;
    }

    // For VehiclesTestedList Component
    if (pathname.startsWith("/vehiclesTested/")) {
      // Check if the determined idType matches the provided idType
      if (determinedIdType === idType && idType) {
        if (idType === "deviceTokenId" && device) {
          if (device.pageType === "usedDevices") {
            return `Devices/Used Devices/${device.deviceName}-${device.operatorName}`;
          } else if (device.pageType === "activeDevices") {
            return `Active Devices/${device.deviceName}-${device.operatorName}`;
          }
        } else if (idType === "buMasterId" && bu) {
          return `Service Stations/${bu.buName}-${bu.buLocation}(${bu.buCode})(${bu.buRegion})`;
        } else if (idType === "makeModelMasterId" && makeModelData) {
          return `Active Vehicles/${makeModelData.makeName}-${makeModelData.modelName}`;
        }
      }
    }
    // For WheelAnalysisReport Component
    // Check if the pathname matches the pattern for dynamic routes
    if (pathname.includes("/wheelsAnalysisReport/")) {
      // Check if the determined idType matches the provided idType
      if (determinedIdType === idType && idType) {
        if (idType === "deviceTokenId" && device) {
          if (device.pageType === "usedDevices") {
            return `Devices/Used Devices/${device.deviceName}-${device.operatorName}/Wheels Analysis/${vehicle.RegistrationNumber}`;
          } else if (device.pageType === "activeDevices") {
            return `Active Devices/${device.deviceName}-${device.operatorName}/Wheels Analysis/${vehicle.RegistrationNumber}`;
          }
        } else if (idType === "buMasterId" && bu) {
          return `Service Stations/${bu.buName}-${bu.buLocation}(${bu.buCode})(${bu.buRegion})/Wheels Analysis/${vehicle.RegistrationNumber}`;
        } else if (idType === "makeModelMasterId" && makeModelData) {
          return `Active Vehicles/${makeModelData.makeName}-${makeModelData.modelName}/Wheels Analysis/${vehicle.RegistrationNumber}`;
        }
      } else if (pageType === "AllVehiclesTestedList") {
        return `Tests/Wheels Analysis/${vehicle.RegistrationNumber}`;
      }
    }

    if (pageType === "AppDownloadPage") {
      return `Applications/Videos`;
    }

    // If the pathname doesn't match any specific case, return a default value
    switch (pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/appDownloadPage":
        return "Applications";
      case "/serviceStations":
        return "Service Stations";
      case "/activeVehicles":
        return "Active Vehicles";
      case "/AllvehiclesTestedList":
        return "Tests";
      case "/unusedDevices":
        return "Devices/Unused Devices";
      case "/userGuide":
        return "Videos";
      case "/usedDevices":
        return "Devices/Used Devices";
      case "/activeDevices":
        return "Active Devices";
      case "/home":
        return "Home";
      case "/create":
        // return "Create";
        if (formType === "model") {
          return "Create Make Model";
        } else if (formType === "serviceStation") {
          return "Create Service Station";
        } else if (formType === "subscriptionPlan") {
          return "Create Subscription Plan";
        } else if (formType === "organization") {
          return "Create Organization Unit";
        }
        return "Create"; // Default title for 'Registered'
      case "/bulkUploadServiceStation":
        return "Create Service Station/Bulk Create";
      case "/registered":
        if (formType === "model") {
          // return "Registered Make Models";
          return "Registered Make Model";
        } else if (formType === "serviceStation") {
          return "Registered Service Station";
        } else if (formType === "subscriptionPlan") {
          return "Registered Subscription Plan";
        }
        return "Registered"; // Default title for 'Registered'
      case "/getAllMakeModelsList":
        return "Registered Make Model";
      case "/getAllSubscriptionPlansList":
        return "Registered Subscription Plan";
      case "/getAllOrganizationsList":
        return "Registered Organization Unit";
      default:
        return "Unknown Path";
    }
  };

  const title = getTitle(location.pathname, idType, device, bu, makeModelData);

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    // Clear user data from session storage
    sessionStorage.removeItem("user");
    // Redirect to the login page or wherever appropriate
    navigate("/");
    // Close the modal
    setShowLogoutModal(false);
  };

  const cancelLogout = () => {
    setShowLogoutModal(false);
    setDropdownVisible(!dropdownVisible);
  };

  const handleViewProfile = () => {
    setShowViewProfileModal(true);
  };

  const cancelShowViewProfile = () => {
    setShowCancelDialog(true);
    setIsUpdateEnable(false);
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };

  const handleCancelConfirm = () => {
    fetchUserList();
    setShowViewProfileModal(false);
    setShowCancelDialog(false);
    setShowChangePasswordModal(false);
    setDropdownVisible(!dropdownVisible);

    // Clear all error messages
    setErrors({}); // For user details
    setPasswordErrors({}); // For password fields

    // Reset state values when modal closes
    setPasswordObject({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  // Toggle the dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  // Validation Function
  const validateInput = (field, value) => {
    let errorMessage = "";
    if (!value) {
      errorMessage = `${field} is a required field.`;
    } else if (value.length > 50) {
      errorMessage = `${field} must be less than 50 characters.`;
    } else if (/[^a-zA-Z.]/.test(value)) {
      // Exclude spaces, only allow alphabetic characters and periods
      errorMessage = `${field} can only contain alphabetic characters. Spaces and numbers are not allowed.`;
    }
    return errorMessage;
  };

  // Input Change Handler
  const handleInputChange = (field, value) => {
    setIsUpdateEnable(true);

    // Automatically capitalize the first letter of the input if it's not already
    let formattedValue = value.charAt(0).toUpperCase() + value.slice(1);

    const errorMessage = validateInput(
      field === "firstName" ? "First Name" : "Last Name",
      formattedValue
    );
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));

    // If there's an error, disable update but still allow input to display
    if (errorMessage) {
      setIsUpdateEnable(false);
    } else {
      setIsUpdateEnable(true);
    }

    // Show the actual value entered, even with invalid characters like spaces, and capitalize the first letter
    setUserDetails((prevState) => ({
      ...prevState,
      [field]: formattedValue, // Set the capitalized value, show invalid characters
    }));
  };

  const handleKeyDown = (field, event) => {
    if (event.key === "Backspace") {
      setIsUpdateEnable(true);
      setUserDetails((prevState) => {
        const currentValue = prevState[field];
        if (currentValue) {
          const updatedValue = currentValue.slice(0, -1); // Remove the last character

          const errorMessage =
            updatedValue.length === 0
              ? `${
                  field.charAt(0).toUpperCase() + field.slice(1)
                } is a required field.`
              : ""; // No error if not empty
          // If there's an error, disable update but still allow input to display
          if (errorMessage) {
            setIsUpdateEnable(false);
          } else {
            setIsUpdateEnable(true);
          }

          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
          }));

          return {
            ...prevState,
            [field]: updatedValue, // Set the updated value
          };
        } else {
          return prevState;
        }
      });

      event.preventDefault(); // Prevent the default backspace behavior
    }
  };

  const handleAddClick = () => {
    setShowConfirmDialog(true); // Open modal on button click
  };

  const handleCancelDilog = () => {
    setShowConfirmDialog(false); // Close modal without action
  };

  const handleConfirm = () => {
    sendDataUpdateUser();
    setShowConfirmDialog(false); // Close modal after confirmation
  };

  const sendDataUpdateUser = async () => {
    try {
      // Dynamically construct the request body
      let body = {
        userMasterId: userDetails.id, // Assuming this is required
      };
      // Flags to determine which modal to show
      let isNameUpdate = false;
      let isPasswordUpdate = false;

      // Conditionally add properties if they have values
      if (userDetails.firstName) {
        body.firstName = userDetails.firstName;
        isNameUpdate = true;
      }
      if (userDetails.lastName) {
        body.lastName = userDetails.lastName;
        isNameUpdate = true;
      }
      if (passwordObject.oldPassword) {
        body.oldPassword = passwordObject.oldPassword;
        isPasswordUpdate = true;
      }
      if (passwordObject.newPassword) {
        body.newPassword = passwordObject.newPassword;
        isPasswordUpdate = true;
      }

      // Make the API call to update user information
      const response = await fetch(serverUrl + "/update/user", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          // showSuccessModelBox(); // Show a success message
          if (isPasswordUpdate) {
            showPasswordSuccessModelBox(); // Show success modal for password update
          } else if (isNameUpdate) {
            showSuccessModelBox(); // Show success modal for name update
          }
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    }
  };

  const showSuccessModelBox = () => {
    setIsSuccessModalVisible(true);
  };

  const handleCloseSuccessModal = () => {
    fetchUserList();
    setIsSuccessModalVisible(false);
    setShowViewProfileModal(false);
    setShowChangePasswordModal(false);
    setDropdownVisible(!dropdownVisible);
  };

  const showPasswordSuccessModelBox = () => {
    setShowPasswordSuccessModal(true);
  };

  const handleClosePasswordSuccessModal = () => {
    // Clear user data from session storage
    sessionStorage.removeItem("user");
    // Redirect to the login page or wherever appropriate
    navigate("/");
    // Close the modal
    setShowPasswordSuccessModal(false);
  };

  // Function to show the Change Password modal
  const showPasswordModal = () => {
    setShowChangePasswordModal(true);
  };

  // Function to validate new password
  const validateNewPassword = (password) => {
    if (password.length < 8 || password.length > 30) {
      return "Password must be between 8 and 30 characters.";
    } else if (/\s/.test(password)) {
      return "Password cannot contain spaces.";
    }
    return ""; // No errors
  };

  // Function to validate that confirm password matches the new password
  const validateConfirmPassword = (newPassword, confirmPassword) => {
    return newPassword !== confirmPassword ? "Passwords do not match." : ""; // No errors
  };

  // Validate old password
  const validateOldPassword = (oldPassword) => {
    return oldPassword ? "" : "Old password is required."; // Error if empty
  };

  // Update form data and handle validation
  const handlePasswordChange = (field, value) => {
    setPasswordObject((prev) => ({ ...prev, [field]: value }));

    // Update error state
    let newErrors = { ...passwordErrors };

    if (field === "newPassword") {
      newErrors.newPassword = validateNewPassword(value);
      if (passwordObject.confirmPassword) {
        newErrors.confirmPassword = validateConfirmPassword(
          value,
          passwordObject.confirmPassword
        );
      }
    } else if (field === "confirmPassword") {
      newErrors.confirmPassword = validateConfirmPassword(
        passwordObject.newPassword,
        value
      );
    } else if (field === "oldPassword") {
      newErrors.oldPassword = validateOldPassword(value);
    }

    setPasswordErrors(newErrors);
  };

  // Check if the form is valid
  const isFormValid = () => {
    return (
      passwordObject.oldPassword &&
      passwordObject.newPassword &&
      passwordObject.confirmPassword &&
      !passwordErrors.oldPassword &&
      !passwordErrors.newPassword &&
      !passwordErrors.confirmPassword
    );
  };

  // Fetch all users from the API
  const fetchUserList = async () => {
    try {
      const response = await fetch(serverUrl + "get/all/userMaster", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}), // Empty body if no parameters needed
      });

      const data = await response.json();

      if (data.entity != null) {
        if (data.entity.length > 0) {
          // After fetching, search for the user using userMasterId from session
          const storedUserData = sessionStorage.getItem("user");

          if (storedUserData) {
            const parsedUserData = JSON.parse(storedUserData);

            const userMasterId = parsedUserData.entity.id; // Get the userMasterId from session

            // Search for the user in the fetched user list
            const foundUser = data.entity.find(
              (user) => user.id === userMasterId
            );

            if (foundUser) {
              setUserDetails(foundUser); // Store the details of the matched user
              // console.log("Alluserdetails", data.entity);
              // console.log("setUserDetails", foundUser);
            } else {
              toastWarning("User not found in the list.", 3000);
            }
          }
        } else {
          console.error("No user found: " + data.entity);
          toastWarning("No users found.", 3000);
        }
      } else if (data.errors != null) {
        let errorDescription = data.errors.errorDescription;
        toastWarning(errorDescription, 3000);
      } else {
        toastWarning("Something went wrong.", 3000);
      }
    } catch (err) {
      console.error("Error fetching user list: ", err);
      toastWarning("Error fetching user list.", 3000);
    }
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  return (
    <div className="col-md-12 mx-0 px-0">
      <header
        style={{
          position: "sticky",
          top: "0",
          zIndex: "1000",
          backgroundColor: "white",
        }}
      >
        <div className="justify-content-between p-3 sticky sticky-header">
          <div className="left d-flex align-items-center">
            {/* <FontAwesomeIcon icon={faHome} size="lg" /> /{" "} */}
            <FontAwesomeIcon icon={faHome} size="lg" />
            {location.pathname !== "/dashboard" && " / "}
            <h4
              className="headerTitles"
              style={{ margin: "0", marginLeft: "5px" }}
            >
              {title}
            </h4>
            {/* Conditionally render the expiry warning based on `showOuExpiryText` */}
          </div>
          {!isFromSuperAdmin && showOuExpiryText && (
            <span
              style={{ marginLeft: "10px", color: "orange", marginTop: "3px" }}
            >
              Your plan is set to expire soon on {ouExpiryDate}. Contact support
              at{" "}
              <a href="mailto:support@rodbounce.com">support@rodbounce.com</a>.
            </span>
          )}

          <div className="right d-flex align-items-center">
            {/* <FontAwesomeIcon icon={faUser} size="lg" />

            {userMasterObject && (
              <span className="mr-2">
                <h5
                  className="headerTitles"
                  style={{
                    marginLeft: "4px",
                    marginBottom: "0px",
                  }}
                >
                  Welcome: {userMasterObject.firstName}{" "}
                  {userMasterObject.lastName} ({userMasterObject.designation})
                </h5>
              </span>
            )} */}

            {userDetails && (
              <span
                className="mr-2"
                onClick={toggleDropdown}
                style={{ cursor: "pointer" }}
              >
                <h5
                  className="headerTitles"
                  style={{
                    marginLeft: "4px",
                    marginBottom: "0px",
                    display: "flex", // Use flexbox for alignment
                    alignItems: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    size="lg"
                    style={{ marginRight: "8px" }}
                  />
                  {userDetails.firstName} {userDetails.lastName} (
                  {userDetails.designation})
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size="sm"
                    style={{ marginLeft: "8px" }}
                  />
                </h5>
              </span>
            )}

            {/* Dropdown menu */}
            {dropdownVisible && (
              <div
                style={{
                  position: "absolute",
                  top: "100%",
                  right: "20px",
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                  zIndex: 1,
                  width: "200px",
                }}
              >
                <ul
                  style={{
                    listStyleType: "none",
                    marginLeft: "10px",
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <li
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleViewProfile}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      style={{ marginRight: "10px" }}
                    />
                    View Profile
                  </li>
                  <li
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      borderBottom: "1px solid #ccc",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={showPasswordModal}
                  >
                    <FontAwesomeIcon
                      icon={faKey}
                      style={{ marginRight: "10px" }}
                    />
                    Change Password
                  </li>
                  <li
                    style={{
                      padding: "10px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={handleLogout}
                  >
                    <FontAwesomeIcon
                      icon={faRightFromBracket}
                      style={{ marginRight: "10px" }}
                    />
                    Logout
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Profile Modal */}
      <Modal
        show={showViewProfileModal}
        onHide={cancelShowViewProfile}
        className="modal-dialog-centered"
        animation={true}
        backdrop="static"
        dialogClassName="modal-full-screen" // Adjust this based on your requirements
      >
        <Modal.Header closeButton>
          <Modal.Title>Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row mb-3">
              <div className="col-md-6">
                <label
                  htmlFor="firstName"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  First Name <span className="visually-hidden">(required)</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={userDetails.firstName}
                  onChange={(e) =>
                    handleInputChange("firstName", e.target.value)
                  }
                  onKeyDown={(e) => handleKeyDown("firstName", e)}
                  className="form-control" // Keep it normal, no red border
                  placeholder="Enter First Name"
                />
                {errors.firstName && (
                  <div className="text-danger">{errors.firstName}</div>
                )}
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="lastName"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  Last Name <span className="visually-hidden">(required)</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  value={userDetails.lastName}
                  onChange={(e) =>
                    handleInputChange("lastName", e.target.value)
                  }
                  onKeyDown={(e) => handleKeyDown("lastName", e)} // Handle key down events
                  className="form-control" // Keep it normal, no red border
                  placeholder="Enter Last Name"
                />
                {errors.lastName && (
                  <div className="text-danger">{errors.lastName}</div>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6">
                <label
                  htmlFor="designation"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  Designation
                </label>
                <input
                  type="text"
                  id="designation"
                  value={userDetails.designation}
                  className="form-control"
                  readOnly
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor="username"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  Username
                </label>
                <input
                  type="text"
                  id="username"
                  value={userDetails.userName} // Ensure this is set correctly
                  className="form-control"
                  readOnly
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={cancelShowViewProfile}>
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={handleAddClick}
            disabled={!isUpdateEnable}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* Change Password Modal */}
      <Modal
        show={showChangePasswordModal}
        onHide={cancelShowViewProfile}
        className="modal-dialog-centered"
        animation={true}
        backdrop="static"
        dialogClassName="modal-full-screen"
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row mb-3">
              <div className="col-md-10">
                <label
                  htmlFor="oldPassword"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  Old Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword.oldPassword ? "text" : "password"}
                    id="oldPassword"
                    className={`form-control ${
                      passwordErrors.oldPassword ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Old Password"
                    onChange={(e) =>
                      handlePasswordChange("oldPassword", e.target.value)
                    } // Just capture input
                    autoComplete="off"
                  />
                  <span
                    className="input-group-text"
                    onClick={() => togglePasswordVisibility("oldPassword")}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      icon={showPassword.oldPassword ? faEye : faEyeSlash}
                    />
                  </span>
                </div>
                {passwordErrors.oldPassword && (
                  <div className="text-danger">
                    {passwordErrors.oldPassword}
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-10">
                <label
                  htmlFor="newPassword"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  New Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword.newPassword ? "text" : "password"}
                    id="newPassword"
                    className={`form-control ${
                      passwordErrors.newPassword ? "is-invalid" : ""
                    }`}
                    placeholder="Enter New Password"
                    onChange={(e) =>
                      handlePasswordChange("newPassword", e.target.value)
                    } // Just capture input
                    autoComplete="off"
                  />
                  <span
                    className="input-group-text"
                    onClick={() => togglePasswordVisibility("newPassword")}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      icon={showPassword.newPassword ? faEye : faEyeSlash}
                    />
                  </span>
                </div>
                {passwordErrors.newPassword && (
                  <div className="text-danger">
                    {passwordErrors.newPassword}
                  </div>
                )}
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-10">
                <label
                  htmlFor="confirmPassword"
                  className="form-label"
                  style={{ fontWeight: "bold" }}
                >
                  Confirm Password
                </label>
                <div className="input-group">
                  <input
                    type={showPassword.confirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    className={`form-control ${
                      passwordErrors.confirmPassword ? "is-invalid" : ""
                    }`}
                    placeholder="Confirm New Password"
                    onChange={(e) =>
                      handlePasswordChange("confirmPassword", e.target.value)
                    } // Just capture input
                    autoComplete="off"
                  />
                  <span
                    className="input-group-text"
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon
                      icon={showPassword.confirmPassword ? faEye : faEyeSlash}
                    />
                  </span>
                </div>
                {passwordErrors.confirmPassword && (
                  <div className="text-danger">
                    {passwordErrors.confirmPassword}
                  </div>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={cancelShowViewProfile}>
            Close
          </button>
          <button
            className="btn btn-primary"
            onClick={handleAddClick}
            disabled={!isFormValid()}
          >
            Update
          </button>
        </Modal.Footer>
      </Modal>

      {/* confirmation Modal */}
      <Modal show={showConfirmDialog} onHide={handleCancelDilog}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to update this User?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDilog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* success Dialog Box*/}
      <Modal show={isSuccessModalVisible} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>User updated successfully..!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Password success Dialog Box*/}
      <Modal
        show={showPasswordSuccessModal}
        onHide={handleClosePasswordSuccessModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Password updated successfully..!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePasswordSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Cancel Confirmation Modal */}
      <Modal show={showCancelDialog} onHide={handleCancelDialogClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCancelConfirm}
            style={{ backgroundColor: "red", borderColor: "red" }}
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCancelDialogClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Logout Confirmation Modal */}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        className="modal-dialog-centered"
        animation={true}
        backdrop="static"
        dialogClassName="modal-full-screen"
      >
        <Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Logout</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to log out?</Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={cancelLogout}>
              Cancel
            </button>
            <button className="btn btn-primary" onClick={confirmLogout}>
              Yes
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Header;
