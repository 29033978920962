import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
  faTrash,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";
import Update from "./Update";

const GetAllSubscriptionPlansList = () => {
  const formType = "subscriptionPlan"; // Extract formType from queryParams
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const [searchTest, setsearchTest] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");
  const [subscriptionPlanData, setSubscriptionPlanData] = useState([]);
  const [subscriptionPlanCount, setSubscriptionPlanCount] = useState(null);
  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const itemsPerPage = 100;
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + subscriptionPlanData.length - 1,
    currentPage * itemsPerPage
  );
  const [subscriptionPlanSorting, setSubscriptionPlanSorting] =
    useState("createdDate");
  const [subscriptionPlanReverse, setSubscriptionPlanReverse] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [deletingListData, setDeletingListData] = useState(null);
  const [isDeleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [isDeleteReConfirmationOpen, setDeleteReConfirmationOpen] =
    useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] =
    useState(null);
  const hasToastShownRef = useRef(false);
  const [showOuListPopup, setShowOuListPopup] = useState(false);

  const handleOuListOpenPopup = (item) => {
    setSelectedSubscriptionPlan(item);
    setShowOuListPopup(true);
  };

  // Get Data From Session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchListData = async () => {
      if (userMasterObject) {
        await fetchSubscriptionPlanData(currentPage);
      }
    };
    fetchListData();
  }, [
    userMasterObject,
    currentPage,
    subscriptionPlanSorting,
    subscriptionPlanReverse,
  ]);

  useEffect(() => {
    if (userMasterObject && searchInputValue === "") {
      setCurrentPage(1); // Reset current page to 1 when search is cleared
      fetchSubscriptionPlanData(1);
    }
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false); // Reset the flag if input is invalid
        return;
      }
      setsearchTest(searchInputValue);
      setCurrentPage(1); // Reset to the first page
      fetchSubscriptionPlanData(1);
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [isSearchButtonClicked, searchInputValue]);

  useEffect(() => {
    if (subscriptionPlanData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [subscriptionPlanData]);

  useEffect(() => {
    if (searchTest && userMasterObject) {
      setCurrentPage(1);
      fetchSubscriptionPlanData(1); // Fetch data for the first page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [searchTest]);

  // Fetch data from the API
  const fetchSubscriptionPlanData = async (page) => {
    setIsLoadingListData(true);
    try {
      const response = await fetch(serverUrl + "/get/subscriptionPlan", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchText: searchTest || "",
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage,
          sortBycolumn: subscriptionPlanSorting,
          sortByFlag: subscriptionPlanReverse,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.subscriptionPlanResultsList != null &&
            data.entity.subscriptionPlanResultsList.length > 0
          ) {
            setSubscriptionPlanData(
              data.entity.subscriptionPlanResultsList || []
            );
            setSubscriptionPlanCount(data.entity.totalCount || 0);
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            handleNoResultsFound();
          }
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;
          toastWarning(errorDiscription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error fetching MakeModel data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const handleCreateButtonClick = () => {
    navigate(`/create?form=${formType || "defaultFormType"}`);
  };

  const openDeleteConfirmation = (e, dataToDelete) => {
    e.stopPropagation();
    setDeletingListData(dataToDelete);
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  //Deletion Confirm Function
  const confirmDelete = async (e) => {
    if (deletingListData) {
      try {
        const response = await fetch(serverUrl + "/subscriptionPlan/delete", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            subscriptionPlanMasterId: deletingListData.id,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.entity != null) {
            toastSuccess("Successfully Deleted");
            const updatedPlans = subscriptionPlanData.filter(
              (plan) => plan.id !== deletingListData.id
            );
            setSubscriptionPlanData([...updatedPlans]);
            setCurrentPage(1); // Resetting to page 1 after deletion
            fetchSubscriptionPlanData(1);
          } else if (data.errors != null) {
            let errorDescription = data.errors.errorDescription;
            toastWarning(errorDescription, 3000);
            return;
          } else {
            toastWarning("Something Went Wrong", 3000);
            return;
          }
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleUpdateClick = (item) => {
    // debugger;
    setSelectedSubscriptionPlan(item);
    setShowUpdateForm(true);
  };

  const handleUpdateComplete = () => {
    setCurrentPage(1);
    fetchSubscriptionPlanData(1);
  };

  // Function to export subscription plan data in CSV
  const exportSubscriptionPlanList = async () => {
    try {
      const response = await fetch(serverUrl + "subscriptionPlan/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchText: searchTest || "", // If you have search functionality
          sortBycolumn: subscriptionPlanSorting, // Pass the sorting column
          sortByFlag: subscriptionPlanReverse, // Pass the boolean flag for sorting direction
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          // Open the CSV file in a new tab
          let exportFile = assetPath + data.entity;

          window.open(exportFile, "_blank");

          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          // Show errors if they exist
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
          return;
        } else {
          toastWarning("Something Went Wrong", 3000);
          return;
        }
      } else {
        console.error("Error exporting Subscription Plan CSV");
        toastWarning("Error exporting Subscription Plan CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  // Pagination Function
  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }
    setCurrentPage(newPage);
  };

  const handleSubscriptionPlanSort = (newSortBy) => {
    setSubscriptionPlanReverse((prevReverse) => !prevReverse); // Toggle the current state
    setSubscriptionPlanSorting(newSortBy); // Update sortBy
    setCurrentPage(1); // Always reset to the first page when sorting changes
  };

  // Function to handle no Vehicles Test found
  const handleNoResultsFound = () => {
    // if (currentPage === 1) {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      // Only show toast if on the first page
      toastWarning("No Subscription Plans Found", 3000);
      setSubscriptionPlanData([]);
    }
    // Reset to initial values if no data is found
    setSubscriptionPlanCount(null);
    setTotalPages(0);
  };

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      return;
    }
    setsearchTest(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  const handleOUViewButtonClick = () => {
    navigate("/getAllOrganizationsList");
  };

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}

      <>
        <Modal
          show={showOuListPopup}
          onHide={() => setShowOuListPopup(false)}
          className="modal-dialog-centered"
          animation={false}
          backdrop="static"
          autoFocus="true"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedSubscriptionPlan &&
              selectedSubscriptionPlan.ouMaster.length === 1
                ? "Assign Organization"
                : "Assign Organizations"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="mb-2"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <table className="table">
                <thead>
                  <tr>
                    <th
                      style={{ border: "1px solid black" }}
                      className="table-head"
                    >
                      Sr. No.
                    </th>
                    <th
                      style={{ border: "1px solid black" }}
                      className="text-left"
                    >
                      Organization Name
                    </th>
                    <th
                      style={{ border: "1px solid black" }}
                      className="table-head"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody style={{ color: "black" }}>
                  {!isLoadingListData &&
                  selectedSubscriptionPlan &&
                  selectedSubscriptionPlan.ouMaster.length > 0 ? (
                    selectedSubscriptionPlan.ouMaster.map((ou, ouIndex) => (
                      <tr
                        className="clickable-row"
                        key={ou.id}
                        style={{
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        {/* Serial number based on ouIndex */}
                        <td style={{ border: "1px solid black" }}>
                          {startingSerialNumber + ouIndex}
                        </td>
                        {/* Display the OU name */}
                        <td
                          className="text-left"
                          style={{ border: "1px solid black" }}
                        >
                          {ou.ouName}
                        </td>
                        {/* Action button */}
                        <td
                          className="text-center"
                          style={{ border: "1px solid black" }}
                        >
                          <Button
                            className="mx-1 btns"
                            variant="primary"
                            onClick={handleOUViewButtonClick}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="3" style={{ textAlign: "center" }}>
                        No OUs Assigned
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </>

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0 main">
          <div className="px-0 mx-0">
            <ToastContainer />
            <div className="row px-0 mx-0 d-flex">
              <div className="col-md-12 mx-0 px-0">
                <Header />
                <div className="row px-2 mx-0">
                  <div
                    className="px-0 my-2"
                    style={{ backgroundColor: "white", borderRadius: "8px" }}
                  >
                    <div
                      className="card-container-test mx-0 mt-2 px-2 pb-3 "
                      style={{ maxheight: "100" }}
                    >
                      <div className="px-0 d-flex justify-content-between">
                        <div className="d-flex mb-2">
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Search"
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              value={searchInputValue}
                              onChange={handleInputChange}
                              style={{
                                width: "25vw",
                                borderRadius: "5px",
                              }}
                            />
                            {searchInputValue && (
                              <FontAwesomeIcon
                                icon={faXmark}
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "30%",
                                  color: "gray",
                                }}
                                onClick={() => {
                                  setsearchTest("");
                                  setSearchInputValue("");
                                }}
                              />
                            )}
                          </div>

                          <Button
                            variant="primary"
                            onClick={handleSearch}
                            className="btns mx-1"
                          >
                            <FontAwesomeIcon icon={faSearch} />
                          </Button>
                        </div>

                        <div className="justify-content-end">
                          <Button
                            className="mx-1 mb-2 btns"
                            variant="primary"
                            onClick={handleCreateButtonClick}
                          >
                            Create
                          </Button>
                          <Button
                            variant="primary"
                            className="mx-1 mb-2 btns"
                            onClick={exportSubscriptionPlanList}
                            disabled={subscriptionPlanData.length === 0}
                          >
                            Export
                          </Button>
                        </div>
                      </div>

                      {/* Delete Modal */}
                      <Modal
                        show={isDeleteConfirmationOpen}
                        onHide={closeDeleteConfirmation}
                        className="modal-dialog-centered"
                        animation={false}
                        backdrop="static"
                        autoFocus="true"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Confirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>
                            {" "}
                            Are you sure you want to delete this Subscription
                            Plan?
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            className="btns"
                            variant="secondary"
                            onClick={closeDeleteConfirmation}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="btns"
                            variant="danger"
                            onClick={(e) => {
                              closeDeleteConfirmation();
                              setDeleteReConfirmationOpen(true);
                              closeDeleteConfirmation();
                            }}
                          >
                            Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      {/* Re-confirmation Modal */}
                      <Modal
                        show={isDeleteReConfirmationOpen}
                        onHide={() => setDeleteReConfirmationOpen(false)} // Close the reconfirmation modal
                        className="modal-dialog-centered"
                        animation={false}
                        backdrop="static"
                        autoFocus="true"
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Reconfirm Delete</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>
                            {" "}
                            Are you sure you want to delete this Subscription
                            Plan? This action is irreversible.
                          </p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            onClick={() => setDeleteReConfirmationOpen(false)} // Close the reconfirmation modal
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="danger"
                            onClick={(e) => {
                              confirmDelete(e);
                              setDeleteReConfirmationOpen(false);
                            }}
                          >
                            Confirm Delete
                          </Button>
                        </Modal.Footer>
                      </Modal>

                      <div className="employee-table">
                        <div
                          className="table-container"
                          style={{
                            maxHeight: "70vh",
                            overflow: "auto",
                          }}
                        >
                          <table>
                            <thead className="fixed-header">
                              <tr>
                                <th>Sr. No</th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleSubscriptionPlanSort(
                                      "planDescription"
                                    )
                                  }
                                >
                                  Plan Description &nbsp;
                                  {subscriptionPlanSorting ===
                                    "planDescription" && (
                                    <FontAwesomeIcon
                                      icon={
                                        subscriptionPlanReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-center"
                                  onClick={() =>
                                    handleSubscriptionPlanSort("duration")
                                  }
                                >
                                  Duration (In Days) &nbsp;
                                  {subscriptionPlanSorting === "duration" && (
                                    <FontAwesomeIcon
                                      icon={
                                        subscriptionPlanReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th className="text-center">Max Devices</th>
                                <th className="text-center">
                                  Organization Count
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleSubscriptionPlanSort("createdDate")
                                  }
                                >
                                  Created Date &nbsp;
                                  {subscriptionPlanSorting ===
                                    "createdDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        subscriptionPlanReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() =>
                                    handleSubscriptionPlanSort("updatedDate")
                                  }
                                >
                                  Updated Date &nbsp;
                                  {subscriptionPlanSorting ===
                                    "updatedDate" && (
                                    <FontAwesomeIcon
                                      icon={
                                        subscriptionPlanReverse
                                          ? faArrowDown
                                          : faArrowUp
                                      }
                                    />
                                  )}
                                </th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {!isLoadingListData &&
                                subscriptionPlanData.length > 0 &&
                                subscriptionPlanData.map((plan, index) => (
                                  <tr
                                    className="clickable-row"
                                    key={plan.id}
                                    style={{
                                      borderBottom: "1px solid #ddd",
                                      backgroundColor: plan.isHighlight
                                        ? "#f5f5a5"
                                        : "transparent",
                                    }}
                                  >
                                    <td>{startingSerialNumber + index}</td>
                                    <td className="text-left">
                                      {plan.planDescription}
                                    </td>
                                    <td className="text-center">
                                      {plan.duration}
                                    </td>
                                    <td className="text-center">
                                      {plan.maxAllowedDevices}
                                    </td>
                                    <td className="text-center">
                                      {/* {plan.ouMaster.length} */}
                                      <Button
                                        className="mx-1 btns"
                                        variant="primary"
                                        disabled={plan.ouMaster.length === 0}
                                        onClick={(e) =>
                                          handleOuListOpenPopup(plan)
                                        }
                                      >
                                        View [{plan.ouMaster.length}]
                                      </Button>
                                    </td>
                                    <td className="text-left">
                                      {new Date(
                                        plan.createdDate
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td className="text-left">
                                      {plan.updatedDate
                                        ? new Date(
                                            plan.updatedDate
                                          ).toLocaleDateString("en-GB")
                                        : "-"}
                                    </td>
                                    <td>
                                      {/* <button
                                        style={{
                                          color: "black",
                                          border: "none",
                                        }}
                                        onClick={(e) => handleUpdateClick(plan)}
                                      >
                                        <FontAwesomeIcon icon={faPen} />
                                      </button> */}
                                      <button
                                        className="delete-btn btns"
                                        // style={{
                                        //   color: "black",
                                        //   border: "none",
                                        //   // marginLeft: "25px",
                                        // }}
                                        style={{
                                          color:
                                            plan.ouMaster.length > 0
                                              ? "grey"
                                              : "black", // Change color if disabled
                                          border: "none",
                                          cursor:
                                            plan.ouMaster.length > 0
                                              ? "not-allowed"
                                              : "pointer", // Show 'not-allowed' cursor
                                          // opacity: plan.ouMaster.length > 0 ? 0.5 : 1, // Reduce opacity if disabled
                                        }}
                                        onClick={(e) =>
                                          openDeleteConfirmation(e, plan)
                                        }
                                        disabled={plan.ouMaster.length > 0} // Disable if there are OUs assigned
                                      >
                                        {/* <FontAwesomeIcon icon={faTrash} /> */}
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          style={{
                                            color:
                                              plan.ouMaster.length > 0
                                                ? "lightgrey"
                                                : "black", // Change icon color if disabled
                                          }}
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {!isLoadingListData &&
                            subscriptionPlanData.length === 0 && (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginTop: "15px",
                                }}
                              >
                                <img src={Notfound} alt="Not Found" />
                                <p
                                  style={{
                                    marginTop: "-10px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  No Subscription Plans Found
                                </p>
                              </div>
                            )}
                        </div>
                      </div>

                      {!isLoadingListData &&
                        subscriptionPlanData.length > 0 && (
                          <div className="col-md-12 mt-3 d-flex justify-content-end">
                            <div className="pagination-buttons">
                              <div className="pagination-button">
                                <div
                                  className={`circle ${
                                    currentPage === 1 ||
                                    subscriptionPlanData.length === 0
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() => handlePagination("prev")}
                                >
                                  <FontAwesomeIcon icon={faChevronLeft} />
                                </div>
                              </div>

                              <div className="pagination-info">
                                <p>
                                  {subscriptionPlanData.length > 0
                                    ? rangeStart
                                    : 0}{" "}
                                  -{" "}
                                  {subscriptionPlanData.length > 0
                                    ? rangeEnd
                                    : 0}{" "}
                                  of{" "}
                                  {subscriptionPlanData.length > 0
                                    ? subscriptionPlanCount
                                    : 0}{" "}
                                </p>
                              </div>

                              <div className="pagination-button">
                                <div
                                  className={`circle ${
                                    currentPage === totalPages ||
                                    subscriptionPlanData.length === 0
                                      ? "disabled"
                                      : ""
                                  }`}
                                  onClick={() => handlePagination("next")}
                                >
                                  <FontAwesomeIcon icon={faChevronRight} />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Render Update Component */}
      {showUpdateForm && (
        <Update
          formType={formType}
          selectedSubscriptionPlan={selectedSubscriptionPlan}
          showUpdateForm={showUpdateForm}
          setShowUpdateForm={setShowUpdateForm}
          handleUpdateComplete={handleUpdateComplete}
        />
      )}
    </>
  );
};

export default GetAllSubscriptionPlansList;
