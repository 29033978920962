import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import { Button, Modal, Form } from "react-bootstrap";
import Header from "./Header";
import "react-toastify/dist/ReactToastify.css"; // Import styles for Toastify
import { serverUrl } from "../../App";
import { ClipLoader } from "react-spinners";
import Select from "react-select";

export const Create = () => {
  const navigate = useNavigate();
  const [userMasterObject, setUserMasterObject] = useState(null);
  const [selectedDropdownOuId, setSelectedDropdownOuId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [ouOptions, setOuOptions] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [show, setShow] = useState(false);
  const [csvTotalBuCount, setCsvTotalBuCount] = useState(null);
  const [newlyAddedCount, setnewlyAddedCount] = useState(null);
  const [existingBuCodesCount, setexistingBuCodesCount] = useState(null);
  const [existingBuCodes, setexistingBuCodes] = useState([]);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [formData, setFormData] = useState({
    serviceStationName: "",
    serviceStationRegion: "",
    serviceStationCode: "",
    serviceStationChannel: "",
    serviceStationLocation: "",
    deviceCount: "",
  });
  const [errors, setErrors] = useState({
    serviceStationName: "",
    serviceStationRegion: "",
    serviceStationCode: "",
    serviceStationChannel: "",
    serviceStationLocation: "",
    deviceCount: "",
  });
  const [modelFormData, setModelFormData] = useState({
    makeName: "",
    modelName: "",
    frontWeight: "",
    backWeight: "",
    frontLeftSecondPeakAmplitudeThreshold: "",
    frontRightSecondPeakAmplitudeThreshold: "",
    backLeftSecondPeakAmplitudeThreshold: "",
    backRightSecondPeakAmplitudeThreshold: "",
    frontLeftMinSecondPeakAmplitudeThreshold: "",
    frontRightMinSecondPeakAmplitudeThreshold: "",
    backLeftMinSecondPeakAmplitudeThreshold: "",
    backRightMinSecondPeakAmplitudeThreshold: "",
  });
  const location = useLocation();
  const [vehicleBodyTypeDropDown, setVehicleBodyTypeDropDown] = useState([]);
  const [selectedVehicleBodyType, setSelectedVehicleBodyType] = useState("");
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const [subscriptionFormData, setSubscriptionFormData] = useState({
    planDescription: "",
    planDuration: "",
    maxAllowedDevices: "",
  });
  const [subscriptionPlanDropDown, setSubscriptionPlanDropDown] = useState([]);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState("");
  const [organizationFormData, setOrganizationFormData] = useState({
    ouName: "",
  });

  const toBulkUploadServiceStation = () => {
    navigate("/bulkUploadServiceStation");
  };

  // Helper function to get query parameters
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  // Extract the 'form' query parameter from the URL
  const queryParams = getQueryParams(location.search);
  const formType = queryParams.get("form"); // 'model' or other values

  // Get data from session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");
        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    if (user?.entity?.roleOuObjs?.length > 0) {
      const uniqueOUs = user.entity.roleOuObjs.map((role) => ({
        id: role.ouMasterObj.id,
        ouName: role.ouMasterObj.ouName,
      }));
      const uniqueOUsFiltered = uniqueOUs.filter(
        (ou, index, self) =>
          index ===
          self.findIndex((t) => t.id === ou.id && t.ouName === ou.ouName)
      );
      setOuOptions(uniqueOUsFiltered);
    } else {
      navigate("/");
    }
  }, [navigate, user]);

  useEffect(() => {
    const fetchOptions = async () => {
      // if (userMasterObject?.ouMasterId) {
      try {
        const response = await fetch(serverUrl + "get/all/bu/make_model", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // ouMasterId: userMasterObject.ouMasterId,
          }),
        });
        const data = await response.json();

        if (data.entity != null) {
          if (
            data.entity.allVehicleBodyTypeList != null &&
            data.entity.allVehicleBodyTypeList.length > 0
          ) {
            setVehicleBodyTypeDropDown(data.entity.allVehicleBodyTypeList);
          } else {
            console.error("No Vehicle body type found: " + data.entity);
            toastWarning("Vehicle body type not found.", 3000);
          }
        }
        if (data.entity != null) {
          if (
            data.entity.allSubscriptionPlanList != null &&
            data.entity.allSubscriptionPlanList.length > 0
          ) {
            setSubscriptionPlanDropDown(data.entity.allSubscriptionPlanList);
          } else {
            console.error("No Subscription Plan found: " + data.entity);
            toastWarning("Subscription Plan not found.", 3000);
          }
        } else if (data.errors != null) {
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
        } else {
          toastWarning("Something Went Wrong", 3000);
        }
      } catch (err) {
        console.error("Error in fetchOptions:", err);
      }
      // }
    };

    fetchOptions();
  }, [userMasterObject]);

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  const sendDataCreateServceStation = async () => {
    // debugger;
    setIsLoading(true); // Set loading to true when sending data

    try {
      const currentDateTime = new Date(); // current date time stand
      const formObject = {
        buRegion: formData.serviceStationRegion,
        buCode: formData.serviceStationCode,
        buName: formData.serviceStationName,
        buLocation: formData.serviceStationLocation,
        buChannel: formData.serviceStationChannel,
        deviceCount: formData.deviceCount,
      };

      const buList = [formObject];

      // console.log("Before Api call:", currentDateTime.toLocaleString());
      const response = await fetch(serverUrl + "bu/create", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId, // Use selectedDropdownOuId here
          buList: buList,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          setnewlyAddedCount(data.entity.newlyAddedCount);

          setexistingBuCodesCount(data.entity.existingBuCodesCount);

          setexistingBuCodes(data.entity.existingBuCodes);

          handleSuccess();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "organizationName") {
      setSelectedDropdownOuId(value); // Update selectedDropdownOuId with the selected value
      // console.log("Selected Dropdown ou id", value);
    }
  };

  const handleFormData = (e) => {
    const { name, value } = e.target;

    if (name === "serviceStationName") {
      const isNameValid =
        value === "" || /^[A-Za-z][A-Za-z0-9.\s]{0,99}$/.test(value); // Allow empty or valid name
      if (isNameValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters, numbers, spaces, and dots are allowed. Should start with a letter and be less than 100 characters.",
        }));
      }
    } else if (name === "serviceStationRegion") {
      const isRegionValid = /^[A-Za-z0-9]{0,3}$/.test(value); // No changes needed here
      if (isRegionValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Alphanumeric characters only, max 3 characters.",
        }));
      }
    } else if (name === "serviceStationCode") {
      const isCodeValid = value === "" || /^[A-Za-z0-9-]{0,20}$/.test(value); // Allow empty or valid code
      if (isCodeValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only alphanumeric characters and dashes (-) are allowed, max 20 characters.",
        }));
      }
    } else if (name === "serviceStationChannel") {
      const isChannelValid = /^[A-Za-z\s]{0,30}$/.test(value); // No changes needed here
      if (isChannelValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters and spaces are allowed, max 30 characters.",
        }));
      }
    } else if (name === "serviceStationLocation") {
      const isLocationValid = /^[A-Za-z\s]{0,50}$/.test(value); // No changes needed here
      if (isLocationValid) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]:
            "Invalid input. Only letters and spaces are allowed, max 50 characters.",
        }));
      }
    } else if (name === "deviceCount") {
      const count = Number(value);
      // Allow empty input to clear the error when backspace is pressed
      if (value.trim() === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value, // Allow empty input
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if input is empty
        }));
      } else if (Number.isInteger(count) && count > 0 && count <= 30) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error if valid
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid input. Must be a positive number between 1 and 30.",
        }));
      }
    }
  };

  const handleCancelDilog = () => {
    setShowConfirmDialog(false);
  };

  const handleAddClick = (e) => {
    // debugger;
    e.preventDefault();
    setShowConfirmDialog(true);
  };

  // const handleConfirm = () => {
  //   // debugger;
  //   setShowConfirmDialog(false);
  //   sendDataCreateServceStation();
  // };
  const handleConfirm = () => {
    setShowConfirmDialog(false);
    if (formType === "model") {
      sendDataCreateModel();
    } else if (formType === "serviceStation") {
      sendDataCreateServceStation();
    } else if (formType === "subscriptionPlan") {
      sendDataCreateSubscriptionPlan();
    } else if (formType === "organization") {
      sendDataCreateOrganization();
    }
  };

  const handleCancelSuccessModal = () => {
    setShow(false);
  };

  // Check if all required fields are filled
  const isFormValid = () => {
    return (
      formData.serviceStationName &&
      formData.serviceStationRegion &&
      formData.serviceStationCode &&
      formData.serviceStationChannel &&
      formData.serviceStationLocation &&
      formData.deviceCount &&
      selectedDropdownOuId
    );
  };

  const handleShowSuccessModal = () => {
    const message = `Successfully added ${newlyAddedCount} service station.`;
    setSuccessMessage(message);
    setShowSuccessModal(true);
  };

  const handleSuccess = () => {
    setSuccessMessage({ newlyAddedCount, existingBuCodesCount });

    handleShowSuccessModal();

    setShow(true);
  };

  // Define handleCancelClick function
  const isAnyFieldFilled = () => {
    return (
      (formData.serviceStationName || "").trim() !== "" ||
      (formData.organizationName || "").trim() !== "" ||
      (formData.serviceStationRegion || "").trim() !== "" ||
      (formData.serviceStationCode || "").trim() !== "" ||
      (formData.serviceStationChannel || "").trim() !== "" ||
      (formData.serviceStationLocation || "").trim() !== "" ||
      (formData.deviceCount || "").trim() !== ""
    );
  };

  // Define handleCancelClick function
  const isAnyFieldFilledModel = () => {
    return (
      (modelFormData.makeName || "").trim() !== "" ||
      (modelFormData.modelName || "").trim() !== "" ||
      (modelFormData.frontWeight || "").trim() !== "" ||
      (modelFormData.backWeight || "").trim() !== "" ||
      (modelFormData.frontLeftSecondPeakAmplitudeThreshold || "").trim() !==
        "" ||
      (modelFormData.frontRightSecondPeakAmplitudeThreshold || "").trim() !==
        "" ||
      (modelFormData.backLeftSecondPeakAmplitudeThreshold || "").trim() !==
        "" ||
      (modelFormData.backRightSecondPeakAmplitudeThreshold || "").trim() !==
        "" ||
      (modelFormData.frontLeftMinSecondPeakAmplitudeThreshold || "").trim() !==
        "" ||
      (modelFormData.frontRightMinSecondPeakAmplitudeThreshold || "").trim() !==
        "" ||
      (modelFormData.backLeftMinSecondPeakAmplitudeThreshold || "").trim() !==
        "" ||
      (modelFormData.backRightMinSecondPeakAmplitudeThreshold || "").trim() !==
        ""
    );
  };

  // Define isAnyFieldFilledSubscriptionPlan function
  const isAnyFieldFilledSubscriptionPlan = () => {
    return (
      (subscriptionFormData.planDescription || "").trim() !== "" ||
      (subscriptionFormData.planDuration || "").trim() !== "" ||
      (subscriptionFormData.maxAllowedDevices || "").trim() !== ""
    );
  };

  const isAnyFieldFilledOrganization = () => {
    return (organizationFormData.ouName || "").trim() !== "";
  };

  const handleCancelClick = () => {
    if (formType === "model") {
      if (isAnyFieldFilledModel()) {
        setShowCancelDialog(true);
      }
    } else if (formType === "serviceStation") {
      if (isAnyFieldFilled()) {
        setShowCancelDialog(true);
      }
    } else if (formType === "subscriptionPlan") {
      if (isAnyFieldFilledSubscriptionPlan()) {
        setShowCancelDialog(true);
      }
    } else if (formType === "organization") {
      if (isAnyFieldFilledOrganization()) {
        setShowCancelDialog(true);
      }
    }
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };

  const handleCancelConfirm = () => {
    navigate("/home"); // Navigate to the home page
  };

  //Validation for Model Form Fields
  const handleModelFormData = (event) => {
    const { name, value } = event.target;
    let errorMessage = "";

    if (value === "") {
      setModelFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      return;
    }

    if (name === "makeName") {
      const isNameValid = /^[A-Za-z]+$/.test(value);
      if (isNameValid) {
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: capitalizedValue,
        }));
      }
    } else if (name === "modelName") {
      const isModelValid = /^[A-Za-z][A-Za-z0-9\s\-().]*$/.test(value);
      if (isModelValid) {
        const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: capitalizedValue,
        }));
      }
    } else if (name === "frontWeight" || name === "backWeight") {
      const weightRegex = /^\d{1,7}(\.\d{0,2})?$/; // Max 7 digits before and 2 after decimal
      if (weightRegex.test(value)) {
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else if (
      [
        "frontLeftSecondPeakAmplitudeThreshold",
        "frontRightSecondPeakAmplitudeThreshold",
        "backLeftSecondPeakAmplitudeThreshold",
        "backRightSecondPeakAmplitudeThreshold",
        "frontLeftMinSecondPeakAmplitudeThreshold",
        "frontRightMinSecondPeakAmplitudeThreshold",
        "backLeftMinSecondPeakAmplitudeThreshold",
        "backRightMinSecondPeakAmplitudeThreshold",
      ].includes(name)
    ) {
      // Regex to match numbers with up to 11 total characters, including decimal point and optional leading minus sign
      const amplitudeRegex = /^-?\d+(\.\d{0,2})?$/;
      // Check if the total length is within 11 characters and only one minus sign is allowed at the start
      const isValidLength = value.length <= 11;
      const isValidSign = /^-?\d/.test(value); // Ensure minus is only at the start

      if (amplitudeRegex.test(value) && isValidLength && isValidSign) {
        setModelFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: "", // Clear error message if valid
        }));
      } else {
        errorMessage = "Enter a valid threshold.";
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [name]: errorMessage,
        }));
      }
    }
  };

  const preventNegativeSign = (event) => {
    const { value, selectionStart } = event.target;

    // Allow minus sign only if it's at the start of the input
    if (event.key === "-" && selectionStart === 0) {
      return; // Allow minus sign if at the start
    }

    // Block additional minus signs and other invalid characters
    if (event.key === "-" || event.key === "e" || event.key === "+") {
      event.preventDefault();
    }
  };

  // Check if all required fields are filled
  const isModelFormValid = () => {
    return (
      modelFormData.makeName &&
      modelFormData.modelName &&
      modelFormData.frontWeight &&
      modelFormData.backWeight &&
      modelFormData.frontLeftSecondPeakAmplitudeThreshold &&
      modelFormData.frontRightSecondPeakAmplitudeThreshold &&
      modelFormData.backLeftSecondPeakAmplitudeThreshold &&
      modelFormData.backRightSecondPeakAmplitudeThreshold &&
      modelFormData.frontLeftMinSecondPeakAmplitudeThreshold &&
      modelFormData.frontRightMinSecondPeakAmplitudeThreshold &&
      modelFormData.backLeftMinSecondPeakAmplitudeThreshold &&
      modelFormData.backRightMinSecondPeakAmplitudeThreshold &&
      selectedDropdownOuId &&
      selectedVehicleBodyType &&
      !Object.values(errorMessages).some((msg) => msg !== "")
    );
  };

  const sendDataCreateModel = async () => {
    // debugger;
    setIsLoading(true); // Set loading to true when sending data

    try {
      // console.log("Id:", selectedVehicleBodyType.id);

      const response = await fetch(serverUrl + "makemodel/save", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId,
          vehicleBodyTypeId: selectedVehicleBodyType.id,
          makeName: modelFormData.makeName,
          modelName: modelFormData.modelName,
          frontStandardWeight: modelFormData.frontWeight,
          backStandardWeight: modelFormData.backWeight,
          frontLeftSecondPeakAmplitudeThreshold:
            modelFormData.frontLeftSecondPeakAmplitudeThreshold,
          frontRightSecondPeakAmplitudeThreshold:
            modelFormData.frontRightSecondPeakAmplitudeThreshold,
          backLeftSecondPeakAmplitudeThreshold:
            modelFormData.backLeftSecondPeakAmplitudeThreshold,
          backRightSecondPeakAmplitudeThreshold:
            modelFormData.backRightSecondPeakAmplitudeThreshold,
          frontLeftMinSecondPeakAmplitudeThreshold:
            modelFormData.frontLeftMinSecondPeakAmplitudeThreshold,
          frontRightMinSecondPeakAmplitudeThreshold:
            modelFormData.frontRightMinSecondPeakAmplitudeThreshold,
          backLeftMinSecondPeakAmplitudeThreshold:
            modelFormData.backLeftMinSecondPeakAmplitudeThreshold,
          backRightMinSecondPeakAmplitudeThreshold:
            modelFormData.backRightMinSecondPeakAmplitudeThreshold,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
    }
  };

  // Function to handle modal visibility
  const showSuccessModelBox = () => {
    setIsSuccessModalVisible(true);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
    //   if (formType === "model") {
    //     navigate(`/registered?form=${formType || "defaultFormType"}`);
    // }
    //  else if (formType === "subscriptionPlan") {

    // }
    // if (formType != null) {
    //   navigate(`/registered?form=${formType || "defaultFormType"}`);
    // } else {
    //   navigate("/home"); // Navigate to the home page
    // }
    //   navigate(`/registered?form=${formType || "defaultFormType"}`);

    if (formType === "model") {
      navigate("/getAllMakeModelsList");
    } else if (formType === "subscriptionPlan") {
      navigate("/getAllSubscriptionPlansList");
    } else if (formType === "organization") {
      navigate("/getAllOrganizationsList");
    } else {
      navigate("/home"); // Navigate to the home page
    }
  };

  const handleViewButtonClick = () => {
    // navigate(`/registered?form=${formType || "defaultFormType"}`);
    if (formType === "model") {
      navigate("/getAllMakeModelsList");
    } else if (formType === "subscriptionPlan") {
      navigate("/getAllSubscriptionPlansList");
    } else if (formType === "organization") {
      navigate("/getAllOrganizationsList");
    } else {
      navigate("/home"); // Navigate to the home page
    }
  };

  // Validation Function for Plan Description, Duration, and Max Allowed Devices
  const validateInput = (field, value) => {
    let errorMessage = "";

    if (field === "planDescription") {
      // Plan Description Validation
      if (!value) {
        errorMessage = `${field} is a required field.`;
      } else if (value.length > 50) {
        errorMessage = `${field} must be less than 50 characters.`;
      } else if (/[^a-zA-Z0-9\s\-_.,{}\[\]()]/.test(value)) {
        // Allow letters, numbers, spaces, hyphen, underscore, comma, dot, {}, [], ()
        errorMessage = "Invalid format.";
      } else if (value.charAt(0) !== value.charAt(0).toUpperCase()) {
        // errorMessage = `${field} must start with a capital letter.`;
      }
    } else if (field === "planDuration" || field === "maxAllowedDevices") {
      // Duration and Max Allowed Devices Validation
      if (!value) {
        errorMessage = `${field} is a required field.`;
      } else if (!/^\d+$/.test(value)) {
        errorMessage = `${field} must contain only numbers.`;
      } else if (parseInt(value, 10) < 1) {
        errorMessage = "Must be ≥ 1."; // Ensure greater than or equal to 1
      }
    }

    return errorMessage;
  };

  const handleSubscriptionPlanInputChange = (field, value) => {
    // Automatically capitalize the first letter for Plan Description
    let formattedValue = value;

    if (field === "planDescription") {
      formattedValue = value.charAt(0).toUpperCase() + value.slice(1);
    }

    // Validate the input
    const errorMessage = validateInput(field, formattedValue);

    // Set error message if any
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));

    // Update subscriptionFormData
    setSubscriptionFormData((prevState) => ({
      ...prevState,
      [field]: formattedValue,
    }));
  };

  const handleKeyDown = (field, event) => {
    if (event.key === "Backspace") {
      setSubscriptionFormData((prevState) => {
        const currentValue = prevState[field];

        // Only handle the case if the current value is not empty
        if (currentValue && currentValue.length > 0) {
          const updatedValue = currentValue.slice(0, -1); // Remove last character

          // Validate the updated value
          const errorMessage =
            updatedValue.length === 0
              ? `${
                  field.charAt(0).toUpperCase() + field.slice(1)
                } is a required field.`
              : validateInput(field, updatedValue);

          // Set error message if any
          setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
          }));

          return {
            ...prevState,
            [field]: updatedValue,
          };
        }

        // Prevent the default backspace behavior
        event.preventDefault();
        return prevState; // Return the previous state if no changes are made
      });
    }
  };

  // Check if all required fields are filled and valid
  const isSubscriptionPlanFormValid = () => {
    return (
      subscriptionFormData.planDescription &&
      subscriptionFormData.planDuration &&
      subscriptionFormData.maxAllowedDevices &&
      !errors.planDescription && // Ensure no errors for planDescription
      !errors.planDuration && // Ensure no errors for planDuration
      !errors.maxAllowedDevices // Ensure no errors for maxAllowedDevices
    );
  };

  const sendDataCreateSubscriptionPlan = async () => {
    // debugger;
    setIsLoading(true); // Set loading to true when sending data

    try {
      const response = await fetch(serverUrl + "save/subscription_plan", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          planDescription: subscriptionFormData.planDescription,
          maxAllowedDevices: subscriptionFormData.maxAllowedDevices,
          duration: subscriptionFormData.planDuration,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
    }
  };

  const handleOrganizationInputChange = (field, value) => {
    let newErrors = { ...errors };

    // Validation for OU Name: no numbers, no special characters, only letters and spaces
    if (field === "ouName") {
      const ouNameRegex = /^[A-Za-z\s]+$/;
      if (!ouNameRegex.test(value)) {
        newErrors.ouName = "Invalid format";
      } else {
        delete newErrors.ouName;
      }
    }

    setErrors(newErrors);
    setOrganizationFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Check if both required fields are filled and valid
  const isOrganizationFormValid = () => {
    return (
      organizationFormData.ouName && selectedSubscriptionPlan && !errors.ouName
    );
  };

  const sendDataCreateOrganization = async () => {
    // debugger;
    setIsLoading(true); // Set loading to true when sending data

    try {
      const response = await fetch(serverUrl + "ou/save", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouName: organizationFormData.ouName,
          subscriptionPlanMasterId: selectedSubscriptionPlan.id,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          showSuccessModelBox();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0">
          <div className="container-fluid mx-0 px-0">
            <ToastContainer />
            <div className="col-md-12 px-0">
              <div className="row px-0 mx-0">
                <div className="col-md-12 px-0">
                  <Header />
                </div>
              </div>

              <div className="row px-1 mx-0" style={{ marginTop: "20px" }}>
                <div style={{ filter: isLoading ? "blur(5px)" : "none" }}>
                  {/* Conditionally Render Model Form */}
                  {formType === "model" && (
                    <div className="row justify-content-center">
                      <div className="col-md-10">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <h5 className="text-center" style={{ flex: 2 }}>
                                Create Make Model
                              </h5>
                              <div className="justify-content-end">
                                <Button
                                  className="mx-1 mb-2 btns"
                                  variant="primary"
                                  onClick={handleViewButtonClick}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                            <Form>
                              {/* Make Name and Model Name */}
                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="makeName"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Make Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="makeName"
                                    id="makeName"
                                    placeholder="Enter Make Name"
                                    value={modelFormData.makeName} // Bind value to formData
                                    onChange={handleModelFormData}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="modelName"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Model Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="modelName"
                                    id="modelName"
                                    placeholder="Enter Model Name"
                                    value={modelFormData.modelName} // Bind value to formData
                                    onChange={handleModelFormData}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              {/* Organization Unit and Vehicle Body Type Dropdowns */}
                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="organizationName"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Select Organization Unit
                                  </label>
                                  <select
                                    id="organizationName"
                                    name="organizationName"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    required
                                    style={{
                                      appearance: "auto",
                                    }}
                                  >
                                    <option value="">
                                      Select Organization
                                    </option>
                                    {ouOptions.map((ou, index) => (
                                      <option key={index} value={ou.id}>
                                        {ou.ouName}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="vehicleBodyType"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Select Vehicle Body Type
                                  </label>
                                  <Select
                                    value={
                                      selectedVehicleBodyType
                                        ? {
                                            value: selectedVehicleBodyType.id,
                                            label:
                                              selectedVehicleBodyType.bodyType,
                                          }
                                        : {
                                            value: null,
                                            label: "Select Body Type",
                                          }
                                    }
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                        zIndex: "999",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                      }),
                                    }}
                                    options={[
                                      {
                                        value: null,
                                        label: "Select Body Type",
                                      },
                                      ...vehicleBodyTypeDropDown
                                        .map((option) => ({
                                          value: option.id,
                                          label: option.bodyType,
                                        }))
                                        .sort((a, b) =>
                                          a.label.localeCompare(b.label)
                                        ), // Sort alphabetically by label
                                    ]}
                                    placeholder="Select"
                                    isSearchable
                                    onChange={(selectedOption) => {
                                      if (selectedOption.value === null) {
                                        setSelectedVehicleBodyType(null);
                                      } else {
                                        const selectedBodyType =
                                          vehicleBodyTypeDropDown.find(
                                            (option) =>
                                              option.id === selectedOption.value
                                          );
                                        setSelectedVehicleBodyType(
                                          selectedBodyType
                                        );
                                      }
                                    }}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              {/* Front Weight and Back Weight Distribution */}
                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="frontWeight"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Front Weight Distribution (kg)
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="frontWeight"
                                    id="frontWeight"
                                    placeholder="Enter Front Weight Distribution"
                                    min="0.01"
                                    value={modelFormData.frontWeight} // Bind value to formData
                                    onChange={handleModelFormData}
                                    onKeyDown={(e) => preventNegativeSign(e)}
                                    required
                                    onInput={(e) => {
                                      if (e.target.value < 0)
                                        e.target.value = "";
                                    }}
                                  />

                                  <div className="invalid-feedback">
                                    This field is required and cannot be
                                    negative.
                                  </div>
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="backWeight"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Back Weight Distribution (kg)
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="backWeight"
                                    id="backWeight"
                                    placeholder="Enter Back Weight Distribution"
                                    min="0.01"
                                    value={modelFormData.backWeight} // Bind value to formData
                                    onChange={handleModelFormData}
                                    onKeyDown={(e) => preventNegativeSign(e)}
                                    required
                                    onInput={(e) => {
                                      if (e.target.value < 0)
                                        e.target.value = "";
                                    }}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required and cannot be
                                    negative.
                                  </div>
                                </div>
                              </div>

                              {/* Peak Amplitude Thresholds */}

                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="frontLeftMinSecondPeakAmplitudeThreshold"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Front Left Min Second Peak Amplitude
                                    Threshold
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="frontLeftMinSecondPeakAmplitudeThreshold"
                                    id="frontLeftMinSecondPeakAmplitudeThreshold"
                                    placeholder="Enter Front Left Min Threshold"
                                    value={
                                      modelFormData.frontLeftMinSecondPeakAmplitudeThreshold
                                    } // Bind value to formData
                                    onChange={handleModelFormData}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="frontRightMinSecondPeakAmplitudeThreshold"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Front Right Min Second Peak Amplitude
                                    Threshold
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="frontRightMinSecondPeakAmplitudeThreshold"
                                    id="frontRightMinSecondPeakAmplitudeThreshold"
                                    placeholder="Enter Front Right Min Threshold"
                                    value={
                                      modelFormData.frontRightMinSecondPeakAmplitudeThreshold
                                    } // Bind value to formData
                                    onChange={handleModelFormData}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="backLeftMinSecondPeakAmplitudeThreshold"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Back Left Min Second Peak Amplitude
                                    Threshold
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="backLeftMinSecondPeakAmplitudeThreshold"
                                    id="backLeftMinSecondPeakAmplitudeThreshold"
                                    placeholder="Enter Back Left Min Threshold"
                                    value={
                                      modelFormData.backLeftMinSecondPeakAmplitudeThreshold
                                    } // Bind value to formData
                                    onChange={handleModelFormData}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="backRightMinSecondPeakAmplitudeThreshold"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Back Right Min Second Peak Amplitude
                                    Threshold
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="backRightMinSecondPeakAmplitudeThreshold"
                                    id="backRightMinSecondPeakAmplitudeThreshold"
                                    placeholder="Enter Back Right Min Threshold"
                                    value={
                                      modelFormData.backRightMinSecondPeakAmplitudeThreshold
                                    } // Bind value to formData
                                    onChange={handleModelFormData}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="frontLeftSecondPeakAmplitudeThreshold"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Front Left Max Second Peak Amplitude
                                    Threshold
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="frontLeftSecondPeakAmplitudeThreshold"
                                    id="frontLeftSecondPeakAmplitudeThreshold"
                                    placeholder="Enter Front Left Max Threshold"
                                    value={
                                      modelFormData.frontLeftSecondPeakAmplitudeThreshold
                                    } // Bind value to formData
                                    onChange={handleModelFormData}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="frontRightSecondPeakAmplitudeThreshold"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Front Right Max Second Peak Amplitude
                                    Threshold
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="frontRightSecondPeakAmplitudeThreshold"
                                    id="frontRightSecondPeakAmplitudeThreshold"
                                    placeholder="Enter Front Right Max Threshold"
                                    value={
                                      modelFormData.frontRightSecondPeakAmplitudeThreshold
                                    } // Bind value to formData
                                    onChange={handleModelFormData}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="backLeftSecondPeakAmplitudeThreshold"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Back Left Max Second Peak Amplitude
                                    Threshold
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="backLeftSecondPeakAmplitudeThreshold"
                                    id="backLeftSecondPeakAmplitudeThreshold"
                                    placeholder="Enter Back Left Max Threshold"
                                    value={
                                      modelFormData.backLeftSecondPeakAmplitudeThreshold
                                    } // Bind value to formData
                                    onChange={handleModelFormData}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="backRightSecondPeakAmplitudeThreshold"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Back Right Max Second Peak Amplitude
                                    Threshold
                                  </label>
                                  <input
                                    type="number"
                                    step="0.01"
                                    className="form-control"
                                    name="backRightSecondPeakAmplitudeThreshold"
                                    id="backRightSecondPeakAmplitudeThreshold"
                                    placeholder="Enter Back Right Max Threshold"
                                    value={
                                      modelFormData.backRightSecondPeakAmplitudeThreshold
                                    } // Bind value to formData
                                    onChange={handleModelFormData}
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              {/* Submit and Cancel Buttons */}
                              <div className="text-center mt-4">
                                <Button
                                  type="submit"
                                  className="mx-2 btn btn-primary"
                                  onClick={handleAddClick}
                                  disabled={!isModelFormValid()}
                                >
                                  Create
                                </Button>

                                <Button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={handleCancelClick}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Create Service Station Form Start */}
                  {formType === "serviceStation" && (
                    <div className="row justify-content-center">
                      <div className="col-md-10">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <h5 className="text-center" style={{ flex: 2 }}>
                                Create Service Station
                              </h5>
                              <Button
                                variant="primary"
                                onClick={toBulkUploadServiceStation}
                              >
                                Bulk Create
                              </Button>
                            </div>
                            <Form>
                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="serviceStationName"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Service Station Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="serviceStationName"
                                    id="serviceStationName"
                                    placeholder="Enter Service Station Name"
                                    value={formData.serviceStationName} // Bind value to formData
                                    onChange={handleFormData}
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>

                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="organizationName"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Select Organization Unit
                                  </label>
                                  <select
                                    id="organizationName"
                                    name="organizationName"
                                    className="form-control"
                                    onChange={handleInputChange}
                                    required
                                    style={{
                                      appearance: "auto",
                                    }}
                                  >
                                    <option value="">
                                      Select Organization
                                    </option>
                                    {ouOptions.map((ou, index) => (
                                      <option key={index} value={ou.id}>
                                        {ou.ouName}
                                      </option>
                                    ))}
                                  </select>
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="serviceStationRegion"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Service Station Region
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="serviceStationRegion"
                                    id="serviceStationRegion"
                                    value={formData.serviceStationRegion}
                                    onChange={handleFormData}
                                    placeholder="Enter Service Station Region"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="serviceStationCode"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Service Station Code
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="serviceStationCode"
                                    id="serviceStationCode"
                                    value={formData.serviceStationCode}
                                    onChange={handleFormData}
                                    placeholder="Enter Service Station Code"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="serviceStationLocation"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Service Station Channel
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="serviceStationChannel"
                                    id="serviceStationChannel"
                                    value={formData.serviceStationChannel}
                                    onChange={handleFormData}
                                    placeholder="Enter Service Station Channel"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                                <div
                                  className="form-group"
                                  style={{
                                    flex: "0 0 48%",
                                    marginBottom: "25px",
                                  }}
                                >
                                  <label
                                    htmlFor="serviceStationLocation"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Service Station Location
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="serviceStationLocation"
                                    id="serviceStationLocation"
                                    value={formData.serviceStationLocation}
                                    onChange={handleFormData}
                                    placeholder="Enter Service Station Location"
                                    required
                                  />
                                  <div className="invalid-feedback">
                                    This field is required.
                                  </div>
                                </div>
                              </div>

                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="deviceCount"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Device Count
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="deviceCount"
                                    name="deviceCount"
                                    value={formData.deviceCount}
                                    onChange={handleFormData}
                                    placeholder="Enter Device Count"
                                    required
                                    min="1"
                                    onInput={(e) => {
                                      if (e.target.value <= 0)
                                        e.target.value = "";
                                    }}
                                  />
                                  <div className="invalid-feedback">
                                    Device count must be a positive number.
                                  </div>
                                </div>
                              </div>

                              <div className="text-center mt-4">
                                <Button
                                  type="submit"
                                  className="mx-2 btn btn-primary"
                                  onClick={handleAddClick}
                                  disabled={!isFormValid()}
                                >
                                  Create
                                </Button>

                                <Button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={handleCancelClick}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Create Service Station Form End */}
                  {/* Conditionally Render Create Subscription Plan Form */}
                  {formType === "subscriptionPlan" && (
                    <div className="row justify-content-center">
                      <div className="col-md-10">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <h5 className="text-center" style={{ flex: 2 }}>
                                Create Subscription Plan
                              </h5>
                              <div className="justify-content-end">
                                <Button
                                  className="mx-1 mb-2 btns"
                                  variant="primary"
                                  onClick={handleViewButtonClick}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                            <Form>
                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                {/* Plan Description Field */}
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="planDescription"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Plan Description
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control" // Consistent border color, no is-invalid class
                                    name="planDescription"
                                    id="planDescription"
                                    value={subscriptionFormData.planDescription}
                                    onChange={(e) =>
                                      handleSubscriptionPlanInputChange(
                                        "planDescription",
                                        e.target.value
                                      )
                                    }
                                    onKeyDown={(e) =>
                                      handleKeyDown("planDescription", e)
                                    }
                                    placeholder="Enter Plan Description"
                                    required
                                  />
                                  {/* Short and simple error message displayed here */}
                                  {errors.planDescription && (
                                    <div className="text-danger">
                                      {errors.planDescription}
                                    </div>
                                  )}
                                </div>

                                {/* Duration Field */}
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="planDuration"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Duration (in Days)
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control" // Consistent border color, no is-invalid class
                                    name="planDuration"
                                    id="planDuration"
                                    value={subscriptionFormData.planDuration}
                                    onChange={(e) =>
                                      handleSubscriptionPlanInputChange(
                                        "planDuration",
                                        e.target.value
                                      )
                                    }
                                    onKeyDown={(e) =>
                                      handleKeyDown("planDuration", e)
                                    }
                                    placeholder="Enter Duration"
                                    required
                                  />
                                  {/* Short and simple error message displayed here */}
                                  {errors.planDuration && (
                                    <div className="text-danger">
                                      {errors.planDuration}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                {/* Max Allowed Devices Field */}
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="maxAllowedDevices"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Max Allowed Devices
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control" // Consistent border color, no is-invalid class
                                    name="maxAllowedDevices"
                                    id="maxAllowedDevices"
                                    value={
                                      subscriptionFormData.maxAllowedDevices
                                    }
                                    onChange={(e) =>
                                      handleSubscriptionPlanInputChange(
                                        "maxAllowedDevices",
                                        e.target.value
                                      )
                                    }
                                    onKeyDown={(e) =>
                                      handleKeyDown("maxAllowedDevices", e)
                                    }
                                    placeholder="Enter Max Allowed Devices"
                                    required
                                  />
                                  {/* Short and simple error message displayed here */}
                                  {errors.maxAllowedDevices && (
                                    <div className="text-danger">
                                      {errors.maxAllowedDevices}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="text-center mt-4">
                                <Button
                                  type="submit"
                                  className="mx-2 btn btn-primary"
                                  onClick={handleAddClick}
                                  disabled={!isSubscriptionPlanFormValid()}
                                >
                                  Create
                                </Button>

                                <Button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={handleCancelClick}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Create Subscription Plan Form End */}
                  {/* Conditionally Render Create Organization Form */}
                  {formType === "organization" && (
                    <div className="row justify-content-center">
                      <div className="col-md-10">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center mb-4">
                              <h5 className="text-center" style={{ flex: 2 }}>
                                Create Organization
                              </h5>
                              <div className="justify-content-end">
                                <Button
                                  className="mx-1 mb-2 btns"
                                  variant="primary"
                                  onClick={handleViewButtonClick}
                                >
                                  View
                                </Button>
                              </div>
                            </div>
                            <Form>
                              <div
                                className="form-row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginBottom: "25px",
                                }}
                              >
                                {/* Organization Name Field */}
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="ouName"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Organization Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="ouName"
                                    id="ouName"
                                    value={organizationFormData.ouName}
                                    onChange={(e) =>
                                      handleOrganizationInputChange(
                                        "ouName",
                                        e.target.value
                                      )
                                    }
                                    placeholder="Enter OU Name"
                                    required
                                  />
                                  {errors.ouName && (
                                    <div className="text-danger">
                                      {errors.ouName}
                                    </div>
                                  )}
                                </div>

                                {/* Subscription Plan Dropdown */}
                                <div
                                  className="form-group"
                                  style={{ flex: "0 0 48%" }}
                                >
                                  <label
                                    htmlFor="subscriptionPlan"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Select Subscription Plan
                                  </label>
                                  <Select
                                    value={
                                      selectedSubscriptionPlan
                                        ? {
                                            value: selectedSubscriptionPlan.id,
                                            label:
                                              selectedSubscriptionPlan.planDescription,
                                          }
                                        : {
                                            value: null,
                                            label: "Select Subscription Plan",
                                          }
                                    }
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                        zIndex: "999",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                      }),
                                    }}
                                    options={[
                                      {
                                        value: null,
                                        label: "Select Subscription Plan",
                                      },
                                      ...subscriptionPlanDropDown
                                        .map((option) => ({
                                          value: option.id,
                                          label: option.planDescription,
                                        }))
                                        .sort((a, b) =>
                                          a.label.localeCompare(b.label)
                                        ), // Sort alphabetically by label
                                    ]}
                                    placeholder="Select"
                                    isSearchable
                                    onChange={(selectedOption) => {
                                      if (selectedOption.value === null) {
                                        setSelectedSubscriptionPlan(null);
                                      } else {
                                        const selectedPlan =
                                          subscriptionPlanDropDown.find(
                                            (option) =>
                                              option.id === selectedOption.value
                                          );
                                        setSelectedSubscriptionPlan(
                                          selectedPlan
                                        );
                                      }
                                    }}
                                  />
                                  {errors.subscriptionPlan && (
                                    <div className="text-danger">
                                      {errors.subscriptionPlan}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="text-center mt-4">
                                <Button
                                  type="submit"
                                  className="mx-2 btn btn-primary"
                                  onClick={handleAddClick}
                                  disabled={!isOrganizationFormValid()}
                                >
                                  Create
                                </Button>

                                <Button
                                  className="btn btn-secondary"
                                  type="button"
                                  onClick={handleCancelClick}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal show={showConfirmDialog} onHide={handleCancelDilog}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formType === "serviceStation" && (
            <p>Are you sure you want to add this service station?</p>
          )}
          {formType === "model" && (
            <p>Are you sure you want to add this model?</p>
          )}
          {formType === "subscriptionPlan" && (
            <p>Are you sure you want to add this Subscription Plan?</p>
          )}
          {formType === "organization" && (
            <p>Are you sure you want to add this Organization?</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDilog}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      {/* success Dialog */}
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>
            {newlyAddedCount > 0 ? <b>Success</b> : <b>Alert</b>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Newly Added Count: {newlyAddedCount}</p>
          <p>Existing BuCodes Count: {existingBuCodesCount}</p>
          <div>
            {newlyAddedCount === 0 ? (
              <>
                <h4>Existing BU Codes</h4>
                <div
                  style={{
                    maxHeight: "150px",
                    overflow: "auto",
                  }}
                >
                  {/* Adjust maxHeight as needed */}
                  <ol>
                    {Object.entries(existingBuCodes).map(([key, value]) => (
                      <li key={key}>{value}</li>
                    ))}
                  </ol>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div style={{ color: "green" }}>
            {newlyAddedCount > 0 ? (
              <div>
                {newlyAddedCount == 1 ? (
                  <b>
                    {newlyAddedCount} Service station added successfully...!
                  </b>
                ) : (
                  <b>
                    {newlyAddedCount} Service stations added successfully...!
                  </b>
                )}
              </div>
            ) : (
              <p></p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* success Dialog Box*/}
      <Modal show={isSuccessModalVisible} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formType === "model" && <p>Model saved successfully..!</p>}
          {formType === "subscriptionPlan" && (
            <p>Subscription Plan saved successfully..!</p>
          )}
          {formType === "organization" && (
            <p>organization saved successfully..!</p>
          )}
          {/* Model saved successfully..! */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSuccessModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Loader spinner */}
      {isLoading && (
        <div
          className="loading-spinner"
          style={{ textAlign: "center", paddingTop: "20px" }}
        >
          <ClipLoader color="#007bff" loading={isLoading} size={50} />
          <p>Please Wait..</p>
        </div>
      )}
      {/* Cancel Confirmation Modal */}
      <Modal show={showCancelDialog} onHide={handleCancelDialogClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleCancelConfirm}
            style={{ backgroundColor: "red", borderColor: "red" }}
          >
            Yes
          </Button>
          <Button variant="secondary" onClick={handleCancelDialogClose}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Create;
