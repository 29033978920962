import React, { useEffect, useState } from "react";
import { Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";

const AppDownloadPage = () => {
  const apkLinkVersion1 =
    "https://suspension.roadbounce.com/RBEAssets/Downloads/RBMaruti_V1.apk";
  const apkLinkVersion2 =
    "https://suspension.roadbounce.com/RBEAssets/Downloads/RBMaruti_V2.apk";
  const apkLinkVersion3 =
    "https://suspension.roadbounce.com/RBEAssets/Downloads/SuspensionPro_V3.apk";
  const userGuideDocVersion1 =
    "https://suspension.roadbounce.com/RBEAssets/Downloads/Suspension Tester App Guide 1.0.pdf";
  const userGuideDocVersion2 =
    "https://suspension.roadbounce.com/RBEAssets/Downloads/Suspension Tester App Guide 2.0.pdf";
  const userGuideDocVersion3 =
    "https://suspension.roadbounce.com/RBEAssets/Downloads/Suspension Tester App Guide 3.0.pdf";
  const navigate = useNavigate();
  const pageType = "AppDownloadPage";

  const handleGetHelp = () => {
    navigate("/userGuide", {
      state: { pageType }, // Pass pageType in the state object
    });
  };

  const handleDownload = async (fileLink, fileName) => {
    // debugger;
    const link = document.createElement("a");
    link.href = fileLink;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="body-pd">
        <div className="secondary-color" style={{ minHeight: "100vh" }}>
          <Header />
          <div className="container-fluid mx-0 p-0">
            <section className="cards-section p-0 m-0 mt-3 row d-flex justify-content-evenly">
              <div className="col-5 p-0 m-0">
                <Card>
                  <Card.Body>
                    <span className="card-titles">Download Latest APK</span>
                    <div className="d-flex justify-content-between">
                      <Card.Text>
                        {apkLinkVersion3 && apkLinkVersion3.length > 54
                          ? apkLinkVersion3.substring(54)
                          : ""}
                      </Card.Text>
                      <Button
                        className="btns"
                        variant="primary"
                        onClick={() => {
                          handleDownload(
                            apkLinkVersion3,
                            "SuspensionPro_V3.apk"
                          );
                        }}
                      >
                        Download APK
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div className="col-5 p-0">
                <Card>
                  <Card.Body>
                    <span className="card-titles"> How To </span>
                    <div className="d-flex justify-content-between">
                      <Card.Text>Videos</Card.Text>
                      <Button
                        className="btns"
                        variant="primary"
                        onClick={handleGetHelp}
                      >
                        <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </section>
            <div
              className="p-2 my-3 mx-1"
              style={{
                maxHeight: "300px",
                overflowY: "auto",
                backgroundColor: "white",
              }}
            >
              <table>
                <thead
                  className="fixed-header"
                  style={{
                    position: "sticky",
                    top: "0",
                    backgroundColor: "white",
                  }}
                >
                  <tr>
                    <th className="text-left border-2">Version</th>
                    <th className="text-left ">Release Date</th>
                    <th className="text-left border-2">User Guide</th>
                    <th className="text-left border-2">Download</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="text-left border-2">1.0</td>
                    <td className="text-left border-2">30 January,2024</td>
                    <td className="text-left border-2">
                      <span
                        onClick={() => {
                          handleDownload(
                            userGuideDocVersion1,
                            "Suspension Tester App Guide 1.0.pdf"
                          );
                        }}
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          fontSize: "18px",
                        }}
                      >
                        Read More
                      </span>
                    </td>
                    <td className="border-2 text-left">
                      <span
                        onClick={() => {
                          handleDownload(apkLinkVersion1, "RBMaruti_V1.apk");
                        }}
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          fontSize: "18px",
                        }}
                      >
                        {apkLinkVersion1 && apkLinkVersion1.length > 54
                          ? apkLinkVersion1.substring(54)
                          : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left border-2">2.0</td>
                    <td className="text-left border-2">25 April,2024</td>
                    <td className="text-left border-2">
                      <span
                        onClick={() => {
                          handleDownload(
                            userGuideDocVersion2,
                            "Suspension Tester App Guide 2.0.pdf"
                          );
                        }}
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          fontSize: "18px",
                        }}
                      >
                        Read More
                      </span>
                    </td>
                    <td className="border-2 text-left">
                      <span
                        onClick={() => {
                          handleDownload(apkLinkVersion2, "RBMaruti_V2.apk");
                        }}
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          fontSize: "18px",
                        }}
                      >
                        {apkLinkVersion2 && apkLinkVersion2.length > 54
                          ? apkLinkVersion2.substring(54)
                          : ""}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-left border-2">3.0</td>
                    <td className="text-left border-2">05 September,2024</td>
                    <td className="text-left border-2">
                      <span
                        onClick={() => {
                          handleDownload(
                            userGuideDocVersion3,
                            "Suspension Tester App Guide 3.0.pdf"
                          );
                        }}
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          fontSize: "18px",
                        }}
                      >
                        Read More
                      </span>
                    </td>
                    <td className="border-2 text-left">
                      {/* <span
                        onClick={() => {
                          handleDownload(
                            apkLinkVersion3,
                            "SuspensionPro_V3.apk"
                          );
                        }}
                        style={{
                          textDecoration: "underline",
                          color: "blue",
                          fontSize: "18px",
                        }}
                      >
                        {apkLinkVersion3 && apkLinkVersion3.length > 54
                          ? apkLinkVersion3.substring(54)
                          : ""}
                      </span> */}
                      <a href="https://suspension.roadbounce.com/RBEAssets/Downloads/SuspensionPro_V3.apk">
                        SuspensionPro_V3.apk
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppDownloadPage;
