import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import {
  faSearch,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faArrowUp,
  faArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { Button } from "react-bootstrap";
import { assetPath } from "../../App";
import { ClipLoader } from "react-spinners";
import Header from "./Header";
import Notfound from "../../Assets/NoResultFound.png";

export const ServiceStaionsList = () => {
  const [userMasterObject, setUserMasterObject] = useState(null);
  const [buData, setBuData] = useState([]);
  const [searchServiceStation, setsearchServiceStation] = useState("");
  const navigate = useNavigate();
  const [buCount, setbuCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Add current page state
  const itemsPerPage = 100; // Set the number of items per page
  const startingSerialNumber = (currentPage - 1) * itemsPerPage + 1;

  // Calculate the range of records being displayed
  const rangeStart = (currentPage - 1) * itemsPerPage + 1;
  const rangeEnd = Math.min(
    rangeStart + buData.length - 1,
    currentPage * itemsPerPage
  );

  const [totalPages, setTotalPages] = useState(0); // Track total pages
  const [isLoadingListData, setIsLoadingListData] = useState(true);
  const [sortBy, setSortBy] = useState("testCount");
  const [reverse, setReverse] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const hasToastShownRef = useRef(false);

  // Get data from session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);
          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    // debugger;
    const fetchServiceStationData = async () => {
      if (userMasterObject) {
        await fetchBuData(currentPage);
        // console.log("fetchServiceStationData useefffect toastelse");
      }
    };

    fetchServiceStationData();
  }, [userMasterObject, currentPage, sortBy, reverse]);

  useEffect(() => {
    // debugger;
    if (userMasterObject && searchInputValue === "") {
      // console.log("searchServiceStation useefffect toastelse");
      setCurrentPage(1); // Reset current page to 1 when search is cleared
      fetchBuData(1);
    }
  }, [userMasterObject, searchInputValue]);

  useEffect(() => {
    if (setBuData.length > 0) {
      hasToastShownRef.current = false;
    }
  }, [setBuData]);

  // Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  // Success Notification
  const toastSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "green" },
    });
  };

  const fetchBuData = async (page) => {
    // debugger;
    // Set loading state to true
    setIsLoadingListData(true);

    try {
      const response = await fetch(serverUrl + "serviceStation/get", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
          searchText: searchServiceStation || "",
          count: itemsPerPage,
          pagesize: (page - 1) * itemsPerPage,
          sortBycolumn: sortBy, // Pass the sorting column
          sortByFlag: reverse, // Pass the boolean flag directly
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.entity != null) {
          if (data.entity.buList != null && data.entity.buList.length > 0) {
            setBuData(data.entity.buList);
            setbuCount(data.entity.totalCount);
            const totalPages = Math.ceil(data.entity.totalCount / itemsPerPage);
            setTotalPages(totalPages);
          } else {
            // console.log("toastelse api");
            // setBuData([]);
            // toastWarning("No service station data found", 3000);
            // No service stations found
            handleNoServiceStationsFound();
          }
        } else if (data.errors != null) {
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
        } else {
          toastWarning("Something Went Wrong", 3000);
        }
      } else {
        console.error("Error fetching BU data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoadingListData(false);
    }
  };

  const handleRowClick = (bu, e) => {
    if (bu) {
      const idType = "buMasterId";
      if (bu.vehicleTestObj.vehicleCount === 0) {
        e.stopPropagation();
        toastWarning("No Records Found", 3000);
        return;
      } else
        navigate(`/vehiclesTested/${bu.buMasterId}/${idType}`, {
          state: { bu },
        });
    } else {
      toastWarning("No Service Station Records Found", 3000);
      return;
    }
  };

  const handlePagination = async (direction) => {
    let newPage;
    if (direction === "prev" && currentPage > 1) {
      newPage = currentPage - 1;
    } else if (direction === "next" && currentPage < totalPages) {
      newPage = currentPage + 1;
    } else {
      return;
    }

    setCurrentPage(newPage);
  };

  const exportList = async () => {
    try {
      const response = await fetch(serverUrl + "servicestations/export", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: userMasterObject.ouMasterId,
          searchText: searchServiceStation || "",
          sortBycolumn: sortBy, // Pass the sorting column
          sortByFlag: reverse, // Pass the boolean flag directly
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          let exportFile = assetPath + data.entity;
          window.open(exportFile, "_blank");
          toastSuccess("Opening CSV in a new tab!");
        } else if (data.errors != null) {
          let errorDescription = data.errors.errorDescription;
          toastWarning(errorDescription, 3000);
        } else {
          toastWarning("Something Went Wrong", 3000);
        }
      } else {
        console.error("Error exporting CSV");
        toastWarning("Error exporting CSV", 3000);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleSort = (newSortBy) => {
    setReverse((prevReverse) => !prevReverse);
    setSortBy(newSortBy);
    setCurrentPage(1);
  };

  // Function to handle no service stations found
  const handleNoServiceStationsFound = () => {
    // if (currentPage === 1) {
    if (currentPage === 1 && !hasToastShownRef.current) {
      hasToastShownRef.current = true;
      // Only show toast if on the first page
      toastWarning("No Service Station Found", 3000);
      setBuData([]);
    }
    // Reset to initial values if no data is found
    setbuCount(null);
    setTotalPages(0);
  };

  useEffect(() => {
    if (isSearchButtonClicked) {
      if (!searchInputValue.trim()) {
        toastWarning("Please enter a search keyword.", 3000);
        setIsSearchButtonClicked(false); // Reset the flag if input is invalid
        return;
      }
      setsearchServiceStation(searchInputValue);
      setCurrentPage(1); // Reset to the first page
      fetchBuData(1);
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [isSearchButtonClicked, searchInputValue]);

  const handleInputChange = (e) => {
    setSearchInputValue(e.target.value);
  };

  const handleSearch = () => {
    // debugger;
    setIsSearchButtonClicked(true);
    if (!searchInputValue.trim()) {
      // toastWarning("Please enter a search keyword.", 3000);
      return;
    }
    setsearchServiceStation(searchInputValue);
    setIsSearchButtonClicked(false);
  };

  useEffect(() => {
    // debugger;
    if (searchServiceStation) {
      // console.log("Fetching data due to search button click");
      setCurrentPage(1);
      fetchBuData(1); // Fetch data for the first page
      setIsSearchButtonClicked(false); // Reset the flag after the data is fetched
    }
  }, [searchServiceStation]);

  return (
    <>
      {isLoadingListData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingListData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0">
          <div className="container-fluid mx-0 px-0">
            <ToastContainer />
            <div className="row d-flex px-0 mx-0">
              <div className="col-md-12 px-0">
                <div className="row px-0 mx-0">
                  <div className="col-md-12 px-0">
                    {/* Header Section Start */}
                    <Header />
                    {/* Header Section End */}
                  </div>
                </div>

                <div
                  className="row p-2 m-2"
                  style={{ backgroundColor: "white", borderRadius: "8px" }}
                >
                  <div className="px-0  d-flex justify-content-between">
                    <div className="d-flex">
                      <div style={{ position: "relative" }}>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          // value={searchServiceStation}
                          value={searchInputValue}
                          // onChange={(e) =>
                          //   setsearchServiceStation(e.target.value)
                          // }
                          onChange={handleInputChange}
                          style={{
                            width: "25vw",
                            borderRadius: "5px",
                          }}
                        />
                        {searchInputValue && (
                          <FontAwesomeIcon
                            icon={faXmark}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "30%",
                              color: "gray",
                            }}
                            onClick={() => {
                              setsearchServiceStation("");
                              setSearchInputValue("");
                            }}
                          />
                        )}
                      </div>

                      <Button
                        className="btns mx-1"
                        variant="primary"
                        onClick={handleSearch}
                      >
                        <FontAwesomeIcon icon={faSearch} size="1x" />
                      </Button>
                    </div>
                    <Button
                      variant="primary"
                      className="mx-1 btns"
                      onClick={exportList}
                      disabled={buData.length === 0}
                    >
                      Export
                    </Button>
                  </div>
                  <div className="col-md-12 mx-0 mt-2 px-0">
                    <div className="card-containerBUs">
                      <div className="employee-table">
                        <div
                          className="table-container"
                          style={{ maxHeight: "70vh", overflow: "auto" }}
                        >
                          <table>
                            <thead
                              className="fixed-header"
                              style={{
                                position: "sticky",
                                top: "0",
                              }}
                            >
                              <tr>
                                <th>Sr. No</th>
                                <th
                                  className="text-left"
                                  onClick={() => handleSort("buName")}
                                >
                                  Name &nbsp;
                                  {sortBy === "buName" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}{" "}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleSort("buLocation")}
                                >
                                  Location &nbsp;
                                  {sortBy === "buLocation" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleSort("buChannel")}
                                >
                                  Channel &nbsp;
                                  {sortBy === "buChannel" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleSort("buRegion")}
                                >
                                  Region &nbsp;
                                  {sortBy === "buRegion" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleSort("buCode")}
                                >
                                  Code &nbsp;
                                  {sortBy === "buCode" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th onClick={() => handleSort("testCount")}>
                                  Vehicles Tested &nbsp;
                                  {sortBy === "testCount" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                                <th
                                  className="text-left"
                                  onClick={() => handleSort("createdDate")}
                                >
                                  Created Date &nbsp;
                                  {sortBy === "createdDate" && (
                                    <FontAwesomeIcon
                                      icon={reverse ? faArrowDown : faArrowUp}
                                    />
                                  )}
                                </th>
                              </tr>
                            </thead>

                            <tbody>
                              {!isLoadingListData &&
                                buData.length > 0 &&
                                buData.map((bu, index) => (
                                  <tr
                                    className="clickable-row"
                                    onClick={(e) => handleRowClick(bu, e)}
                                    key={bu.buMasterId}
                                    style={{
                                      borderBottom: "1px solid #ddd",
                                    }}
                                  >
                                    <td>{startingSerialNumber + index}</td>
                                    <td className="text-left">{bu.buName}</td>
                                    <td className="text-left">
                                      {bu.buLocation}
                                    </td>
                                    <td className="text-left">
                                      {bu.buChannel}
                                    </td>
                                    <td className="text-left">{bu.buRegion}</td>
                                    <td className="text-left">{bu.buCode}</td>
                                    <td>{bu.vehicleTestObj.vehicleCount}</td>
                                    {/* Format the date using toLocaleDateString */}
                                    <td className="text-left">
                                      {new Date(
                                        bu.createdDate
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          {!isLoadingListData && buData.length === 0 && (
                            <div
                              style={{
                                textAlign: "center",
                                marginTop: "15px",
                              }}
                            >
                              <img src={Notfound} alt="Not Found" />
                              <p
                                style={{
                                  marginTop: "-10px",
                                  fontWeight: "bold",
                                }}
                              >
                                No Service Station Found
                              </p>
                            </div>
                          )}
                        </div>
                      </div>

                      {!isLoadingListData && buData.length > 0 && (
                        <div className="col-md-12 mt-3 d-flex justify-content-end">
                          <div className="pagination-buttons">
                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === 1 || buData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("prev")}
                              >
                                <FontAwesomeIcon icon={faChevronLeft} />
                              </div>
                            </div>

                            <div className="pagination-info">
                              <p>
                                {/* {rangeStart} - {rangeEnd} of {buCount}{" "} */}
                                {buData.length > 0 ? rangeStart : 0} -{" "}
                                {buData.length > 0 ? rangeEnd : 0} of{" "}
                                {buData.length > 0 ? buCount : 0}{" "}
                              </p>
                            </div>

                            <div className="pagination-button">
                              <div
                                className={`circle ${
                                  currentPage === totalPages ||
                                  buData.length === 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={() => handlePagination("next")}
                              >
                                <FontAwesomeIcon icon={faChevronRight} />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceStaionsList;
