import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { serverUrl } from "../../App";
import { toast } from "react-toastify";
import { Button, Modal, Form, Card } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import Header from "./Header";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

export const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [ouData, setOuData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedOuPropertiesOuName, setSelectedOuPropertiesOuName] =
    useState("");
  const [selectedOuProperties, setSelectedOuProperties] = useState([]);
  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [changedProperties, setChangedProperties] = useState([]);
  const [isModified, setIsModified] = useState(false);
  // const pageType = "superAdmin";
  const pageType = "home";

  // Retrieve and store the original user data once
  useEffect(() => {
    const initializeUserData = () => {
      // Check if original user data is already stored
      const originalUserData = sessionStorage.getItem("originalUserData");
      if (!originalUserData) {
        // Retrieve user data from sessionStorage
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          // Parse and store the user data under a new key
          sessionStorage.setItem("originalUserData", storedUserData);
        } else {
          console.error("No user data found in session storage.");
        }
      }
    };

    initializeUserData();
  }, []); // Empty dependency array ensures this runs only once

  // Restore original user data if navigating to /superAdmin
  useEffect(() => {
    if (location.pathname === "/home") {
      // Retrieve and restore original data
      const originalUserData = sessionStorage.getItem("originalUserData");
      if (originalUserData) {
        sessionStorage.setItem("user", originalUserData);
      } else {
        console.error("No original user data found in session storage.");
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    fetchOuData(); // Fetch data when component mounts
  }, []);

  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  const fetchOuData = async () => {
    setIsLoadingData(true);
    try {
      const response = await fetch(serverUrl + "/get/ouProperties", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          if (data.entity != null && data.entity.length > 0) {
            // Group data by ouMaster.id
            const groupedData = data.entity.reduce((acc, item) => {
              const {
                ouMaster,
                propertyName,
                propertyValue,
                id,
                propertyLocation,
                propertyDescription,
              } = item; // id is the OuPropertyId

              if (ouMaster && ouMaster.id) {
                const ouId = ouMaster.id;

                if (!acc[ouId]) {
                  acc[ouId] = {
                    ouId: ouId,
                    ouName: ouMaster.ouName || "Unknown Name",
                    ouExpiryDate: ouMaster.expiryDate || "null",
                    ouPropertyObjs: [],
                  };
                }

                acc[ouId].ouPropertyObjs.push({
                  propertyName: propertyName || "Unknown Property",
                  propertyValue: propertyValue || "Unknown Value",
                  OuPropertyId: id || "Unknown ID", // Use id as OuPropertyId
                  propertyLocation: propertyLocation || "Unknown Location",
                  propertyDescription:
                    propertyDescription || "Unknown Description",
                });
              }

              return acc;
            }, {});

            // Convert the grouped object to an array
            let formattedData = Object.values(groupedData);

            // Sort the ouPropertyObjs for each ouId in ascending order by propertyName
            formattedData = formattedData.map((ouItem) => {
              ouItem.ouPropertyObjs.sort((a, b) =>
                a.propertyName.localeCompare(b.propertyName)
              );
              return ouItem;
            });

            // Sort the formattedData by ascending ouName
            formattedData = formattedData.sort((a, b) =>
              a.ouName.localeCompare(b.ouName)
            );

            // Set state with grouped data
            setOuData(formattedData);
          } else {
            console.error("No Ou Properties Found: " + data.entity);
            toastWarning("No Ou Properties Found", 3000);
            return;
          }
        } else if (data.errors != null) {
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        console.error("Error fetching dashboard count data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      await new Promise((resolve) => setTimeout(resolve, 125));
      setIsLoadingData(false);
    }
  };

  const handleShowModal = (ouPropertyObjs, ouName) => {
    setSelectedOuProperties(ouPropertyObjs || []); // Set the properties to display in the modal
    setSelectedOuPropertiesOuName(ouName);
    setShowModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModified(false);
    setSelectedOuProperties([]);
    setChangedProperties([]);
    setShowModal(false);
  };

  const handlePropertyChange = (id, newValue) => {
    // Update the selected OU properties
    setSelectedOuProperties((prevProperties) =>
      prevProperties.map((property) =>
        property.OuPropertyId === id
          ? { ...property, propertyValue: newValue }
          : property
      )
    );

    // Update the changed properties
    setChangedProperties((prevChanges) => {
      const existingChange = prevChanges.find(
        (change) => change.ouPropertyId === id
      );
      const property = selectedOuProperties.find(
        (prop) => prop.OuPropertyId === id
      );
      const propertyName = property ? property.propertyName : "";

      let newChanges;
      if (existingChange) {
        newChanges = prevChanges.map((change) =>
          change.ouPropertyId === id
            ? { ...change, propertyValue: newValue, propertyName }
            : change
        );
      } else {
        newChanges = [
          ...prevChanges,
          { ouPropertyId: id, propertyValue: newValue, propertyName },
        ];
      }

      // Check if there are any unsaved changes
      const isAnyModified = newChanges.some(
        (change) =>
          change.propertyValue !==
          selectedOuProperties.find(
            (prop) => prop.OuPropertyId === change.ouPropertyId
          ).propertyValue
      );
      setIsModified(isAnyModified);

      return newChanges;
    });
  };

  const handleSaveChanges = () => {
    setIsModified(false);
    setShowModal(false);
    setShowConfirmSave(true);
  };

  const handleConfirmSaveChanges = async () => {
    try {
      if (changedProperties && changedProperties.length > 0) {
        const response = await fetch(serverUrl + "/ouProperty/update", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            saveOuPropertiesChanges: changedProperties,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          if (data.entity != null) {
            setShowConfirmSave(false);
            setShowModal(false);
            await fetchOuData();
          } else if (data.errors != null) {
            let errorDiscription = data.errors.errorDescription;
            toastWarning(errorDiscription, 3000);
            return;
          } else {
            toastWarning("Something Went Wrong", 3000);
            return;
          }
        }
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    }
  };

  const handleViewButtonClick = (ouPropertyObjs, ouId) => {
    // Retrieve the stored data
    const storedUserData = sessionStorage.getItem("user");
    if (!storedUserData) {
      console.error("No user data found in session storage.");
      return;
    }

    // Parse the JSON string into an object
    const data = JSON.parse(storedUserData);

    // Initialize an empty array to store the results
    let roleOuObjs = [];

    // Extract the roleOuObjs array from data
    const roleOuObjsData = data.entity.roleOuObjs || [];

    // Search for objects that match the target ouId and push them to roleOuObjsData
    roleOuObjsData.forEach((obj) => {
      if (obj.ouMasterObj && obj.ouMasterObj.id === ouId) {
        obj.ouMasterObj.ouPropertyObjs = ouPropertyObjs;
        roleOuObjs.push(obj);
      }
    });

    // Replace the existing roleOuObjs in the session storage data
    data.entity.roleOuObjs = roleOuObjs;

    // Save the updated data back to session storage
    sessionStorage.setItem("user", JSON.stringify(data));

    // Navigate to the dashboard with query parameter
    navigate("/dashboard", { state: { pageType } });
  };

  return (
    <>
      {isLoadingData && (
        <div className="loading-spinner">
          <ClipLoader color="#007bff" loading={isLoadingData} size={50} />
          <p className="loading-text">Fetching Data...</p>
        </div>
      )}

      <>
        {/* OU Settings Modal */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          dialogClassName="my-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{selectedOuPropertiesOuName} Settings</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {selectedOuProperties.map((property) => (
                <div
                  key={property.OuPropertyId}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "10px",
                    padding: "10px",
                    borderBottom: "1px solid #ddd",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Label style={{ flex: "0 0 300px" }}>
                      <strong>
                        {property.propertyName
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase())}
                        :
                      </strong>
                    </Form.Label>
                    <div style={{ flex: 1 }}>
                      {property.propertyValue === "true" ||
                      property.propertyValue === "false" ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div style={{ marginRight: "10px" }}>
                            <Form.Check
                              type="radio"
                              label="True"
                              name={`radio-${property.OuPropertyId}`}
                              value="true"
                              checked={property.propertyValue === "true"}
                              onChange={() =>
                                handlePropertyChange(
                                  property.OuPropertyId,
                                  "true"
                                )
                              }
                            />
                          </div>
                          <div>
                            <Form.Check
                              type="radio"
                              label="False"
                              name={`radio-${property.OuPropertyId}`}
                              value="false"
                              checked={property.propertyValue === "false"}
                              onChange={() =>
                                handlePropertyChange(
                                  property.OuPropertyId,
                                  "false"
                                )
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <Form.Control
                          type="text"
                          value={property.propertyValue || ""} // Ensure the value is never undefined
                          onChange={(e) =>
                            handlePropertyChange(
                              property.OuPropertyId,
                              e.target.value
                            )
                          }
                          style={{ width: "100%" }}
                        />
                      )}
                    </div>
                  </div>
                  {property.propertyLocation && (
                    <div
                      style={{
                        fontSize: "0.85rem",
                        color: "#6c757d",
                        marginTop: "5px",
                      }}
                    >
                      Location: {property.propertyLocation}
                    </div>
                  )}
                  {property.propertyDescription && (
                    <div
                      style={{
                        fontSize: "0.85rem",
                        color: "#6c757d",
                        marginTop: "2px",
                      }}
                    >
                      Description: {property.propertyDescription}
                    </div>
                  )}
                </div>
              ))}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={!isModified}
              onClick={handleSaveChanges}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Confirmation Modal */}
        <Modal show={showConfirmSave} onHide={() => setShowConfirmSave(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Update Changes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to update changes?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowConfirmSave(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmSaveChanges}>
              Update Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>

      <div className="body-pd secondary-color">
        <div className="container-fluid px-0 mx-0">
          <div className="container-fluid mx-0 px-0">
            <ToastContainer />
            <div className="col-md-12 px-0">
              <div className="row px-0 mx-0">
                <div className="col-md-12 px-0">
                  {/* Header Section Start */}
                  <Header />
                  {/* Header Section End */}
                </div>
              </div>
              <div className="cards-section">
                <div className="row px-1 mx-0">
                  {ouData.map((ou) => (
                    <div key={ou.ouId} className="col-md-3 p-3">
                      {/* <div className="px-2 mx-2"> */}
                      <Card>
                        <Card.Body>
                          <div className="d-flex flex-column">
                            {/* Container for Title and Icon */}
                            <div className="d-flex align-items-center mb-2">
                              <Card.Title
                                className="mx-0"
                                style={{
                                  textTransform: "uppercase",
                                  fontSize: "1.2rem",
                                  flexGrow: 1, // Allows the title to take up available space
                                }}
                              >
                                {ou.ouName}
                              </Card.Title>

                              {/* Check if the plan is expiring within the next 7 days */}
                              {new Date(ou.ouExpiryDate) <
                                new Date(
                                  Date.now() + 7 * 24 * 60 * 60 * 1000
                                ) && (
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle} // Use an appropriate icon for warning
                                  className={
                                    new Date(ou.ouExpiryDate) < new Date()
                                      ? "text-danger"
                                      : "text-warning" // Red if expired, yellow if expiring soon
                                  }
                                  title={
                                    new Date(ou.ouExpiryDate) < new Date()
                                      ? "This plan has expired!"
                                      : "This plan is expiring soon!"
                                  }
                                  style={{ fontSize: "2.0rem" }} // Adjust size as needed
                                />
                              )}
                            </div>

                            {/* Container for Plan Expiry */}
                            <div
                              className="d-flex align-items-center"
                              style={{ marginTop: "-12px" }}
                            >
                              {/* <span>Plan Expiry: {ou.ouExpiryDate}</span> */}
                              {new Date(ou.ouExpiryDate) < new Date()
                                ? "Plan Expired"
                                : `Plan Expiry: ${ou.ouExpiryDate}`}
                            </div>

                            {/* Action Buttons */}
                            <div className="mt-2 d-flex justify-content-end">
                              <Button
                                variant="primary"
                                onClick={() =>
                                  handleViewButtonClick(
                                    ou.ouPropertyObjs,
                                    ou.ouId
                                  )
                                }
                              >
                                View
                                <FontAwesomeIcon
                                  className="px-1"
                                  icon={faArrowRight}
                                />
                              </Button>
                              <Button
                                variant="primary"
                                className="mx-1 btns"
                                onClick={() =>
                                  handleShowModal(ou.ouPropertyObjs, ou.ouName)
                                }
                              >
                                Setting
                              </Button>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                      {/* </div> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
