import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Papa from "papaparse";
import { serverUrl } from "../../App";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { ClipLoader } from "react-spinners";
import Header from "./Header";

function BulkUploadServiceStation() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [userMasterObject, setUserMasterObject] = useState(null);
  const navigate = useNavigate();
  const [ouOptions, setOuOptions] = useState([]);
  const [csvData, setCsvData] = useState([]);
  let [isReadyToAdd, setIsReadyToAdd] = useState(false);
  let [fileUploaded, setFileUploaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDropdownOuId, setSelectedDropdownOuId] = useState();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [show, setShow] = useState(false);
  const [newlyAddedCount, setnewlyAddedCount] = useState(null);
  const [existingBuCodesCount, setexistingBuCodesCount] = useState(null);
  const [existingBuCodes, setexistingBuCodes] = useState([]);
  const [csvTotalBuCount, setCsvTotalBuCount] = useState(null);
  const [isCsvInvalid, setIsCsvInvalid] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const formType = queryParams.get("form"); // Extract formType from queryParams
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [buNameError, setBuNameError] = useState("");
  const [invalidRowIndices, setInvalidRowIndices] = useState([]);

  // Get data from session
  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUserData = sessionStorage.getItem("user");

        if (storedUserData) {
          const parsedUserData = JSON.parse(storedUserData);

          if (
            parsedUserData.entity &&
            parsedUserData.entity.roleOuObjs.length > 0
          ) {
            const ouMasterId =
              parsedUserData.entity.roleOuObjs[0].ouMasterObj.id;
            setUserMasterObject({
              firstName: parsedUserData.entity.firstName,
              lastName: parsedUserData.entity.lastName,
              designation: parsedUserData.entity.designation,
              ouMasterId,
            });
          } else {
            toastWarning("User data not available.", 3000);
            return;
          }
        } else {
          navigate(`/`);
        }
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    if (selectedDropdownOuId && csvData.length > 0) {
      // Check if both dropdown value and CSV data are selected
      setIsReadyToAdd(true); // Enable the button
    } else {
      setIsReadyToAdd(false); // Disable the button
    }
  }, [selectedDropdownOuId, csvData]);

  useEffect(() => {
    // Check if user data exists
    if (
      user &&
      user.entity &&
      user.entity.roleOuObjs &&
      user.entity.roleOuObjs.length > 0
    ) {
      // Extract unique OU names and their corresponding ids
      const uniqueOUs = user.entity.roleOuObjs.map((role) => ({
        id: role.ouMasterObj.id,
        ouName: role.ouMasterObj.ouName,
      }));

      // Remove duplicates
      const uniqueOUsFiltered = uniqueOUs.filter(
        (ou, index, self) =>
          index ===
          self.findIndex((t) => t.id === ou.id && t.ouName === ou.ouName)
      );

      // Set OU options in state
      setOuOptions(uniqueOUsFiltered);
      // console.log("ouOptions",ouOptions);
    } else {
      // Handle case when user data is not available
      navigate("/");
    }
  }, [navigate, user]);

  //Fail Notification
  const toastWarning = (message, seconds) => {
    toast.error(message, {
      position: "top-right",
      autoClose: seconds,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      style: { color: "red" },
    });
  };

  const handleCancelDilog = () => {
    setShowConfirmDialog(false); // Close the confirm dialog
  };

  const handleAddClick = () => {
    setShowConfirmDialog(true); // Open the Confirm Dialog
  };

  const handleConfirm = () => {
    setShowConfirmDialog(false); // Close the confirm dialog

    sendDataToAPI(); // Proceed with API call
  };

  const handleShowSuccessModal = () => {
    setShowSuccessModal(true);
  };

  const handleSuccess = () => {
    setSuccessMessage({ newlyAddedCount, existingBuCodesCount });

    handleShowSuccessModal();

    setShow(true);

    const handleCancelSuccessModal = () => {
      setShow(false); // Close the success dialog
    };
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "organizationName") {
      setSelectedDropdownOuId(value); // Update selectedDropdownOuId with the selected value
      // console.log("Selected Dropdown ou id", value);
    }
  };

  // const handleFileUpload = (event) => {
  //   const file = event.target.files[0];

  //   if (!file) {
  //     console.error("No file selected.");
  //     return;
  //   }

  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     const csvData = e.target.result;

  //     Papa.parse(csvData, {
  //       header: true,
  //       complete: (result) => {
  //         // console.log("Parsed CSV data:", result.data); // Log the parsed data
  //         const parsedData = result.data.map((row) => {
  //           // Check if any row has extra columns or fewer columns
  //           const isValidRow = Object.keys(row).length === 6; // Replace '6' with the expected number of columns

  //            // Check buName length
  //         if (row.buName && row.buName.length > 100) {
  //           setBuNameError('BU Name exceeds character limit of 100.');
  //         } else {
  //           setBuNameError(''); // Clear error if within limit
  //         }
  //           return {
  //             ...row,
  //           };
  //         });
  //         setCsvData(parsedData);
  //         setFileUploaded(true); // Set fileUploaded to true after parsing

  //         // Check for any invalid rows
  //         const invalidRows = parsedData.filter(
  //           (row) => Object.keys(row).length !== 6
  //         );
  //         setIsCsvInvalid(invalidRows.length > 0); // Set invalid CSV file status

  //         // console.log("Invalid rows:", invalidRows);
  //       },
  //     });
  //   };

  //   reader.readAsText(file);
  // };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const csvData = e.target.result;

      Papa.parse(csvData, {
        header: true,
        complete: (result) => {
          let hasInvalidRows = false;
          let buNameErrorFlag = false;
          const invalidRowIndices = []; // Store indices of invalid rows

          const parsedData = result.data.map((row, index) => {
            // Check if the row has the expected number of columns
            const isValidRow = Object.keys(row).length === 6;

            // Check if buName exceeds 100 characters
            const buNameIsValid = row.buName && row.buName.length <= 100;

            // If this row is invalid, add its index to the list
            if (!isValidRow || !buNameIsValid) {
              invalidRowIndices.push(index); // Add invalid row index to the list
              hasInvalidRows = true; // Mark that invalid rows exist

              if (!buNameIsValid) {
                buNameErrorFlag = true; // Set the buName error flag if buName exceeds the limit
              }
            }

            return { ...row }; // Return the row without adding isValid
          });

          // Set parsed CSV data
          setCsvData(parsedData);
          setFileUploaded(true); // File is uploaded and parsed

          // Set invalid CSV status based on row validation
          setIsCsvInvalid(hasInvalidRows);

          // Set buNameError message if any row exceeds 100 characters
          if (buNameErrorFlag) {
            setBuNameError("BU Name exceeds character limit of 100.");
          } else {
            setBuNameError(""); // Clear the error if no issues
          }

          // Save the indices of all invalid rows
          setInvalidRowIndices(invalidRowIndices); // Track all invalid rows
        },
      });
    };

    reader.readAsText(file);
  };

  const sendDataToAPI = async () => {
    // debugger;

    setIsLoading(true); // Set loading to true when sending data

    try {
      const currentDateTime = new Date(); // current date time stand

      // console.log("Before Api call:", currentDateTime.toLocaleString());
      const response = await fetch(serverUrl + "bu/create", {
        // API call to send data...
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ouMasterId: selectedDropdownOuId, // Use selectedDropdownOuId here
          buList: csvData,
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (data.entity != null) {
          setnewlyAddedCount(data.entity.newlyAddedCount);

          setexistingBuCodesCount(data.entity.existingBuCodesCount);

          setexistingBuCodes(data.entity.existingBuCodes);

          setCsvTotalBuCount(data.entity.csvBuListSize);

          handleSuccess();
        } else if (data.errors != null) {
          //check data.errors if not null then show toast data.errors.errorDescription
          let errorDiscription = data.errors.errorDescription;

          toastWarning(errorDiscription, 3000);

          return;
        } else {
          toastWarning("Something Went Wrong", 3000);

          return;
        }
      } else {
        // Failed to send data
        console.error("Failed to send data");
      }
    } catch (error) {
      // Error occurred
      console.error("Error sending data:", error);
    } finally {
      // Set loading to false after API call is complete
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setFormData({
      serviceStationName: "",
      serviceStationChannel: "",
      serviceStationRegion: "",
      serviceStationCode: "",
      serviceStationContact: "",
      serviceStationLocation: "",
      organizationName: "",
    });

    setCsvData([null]);
    setFileUploaded(null);
    setSelectedDropdownOuId(null);
    // Reset the isRequired state as well, if needed
    setIsRequired({
      serviceStationName: false,
      serviceStationChannel: false,
      serviceStationRegion: false,
      serviceStationCode: false,
      serviceStationContact: false,
      serviceStationLocation: false,
      organizationName: false,
    });
  };

  const [formData, setFormData] = useState({
    serviceStationName: "",
    serviceStationChannel: "",
    serviceStationRegion: "",
    serviceStationCode: "",
    serviceStationContact: "",
    serviceStationLocation: "",
    organizationName: "1", // Default value
  });

  const [isRequired, setIsRequired] = useState({
    serviceStationName: false,
    serviceStationChannel: false,
    serviceStationRegion: false,
    serviceStationCode: false,
    serviceStationContact: false,
    serviceStationLocation: false,
    organizationName: false,
  });

  const handleCancelSuccessModal = () => {
    setShow(false); // Close the success dialog
    navigate(`/home`);
  };

  const toSingleCreateServiceStation = () => {
    // navigate("/create");
    navigate(`/create?form=serviceStation`);
  };

  const handleCancelClick = () => {
    setFormData({
      serviceStationName: "",
      serviceStationChannel: "",
      serviceStationRegion: "",
      serviceStationCode: "",
      serviceStationContact: "",
      serviceStationLocation: "",
      organizationName: "",
    });

    setCsvData([null]);
    setFileUploaded(null);
    setSelectedDropdownOuId(null);
    // Reset the isRequired state as well, if needed
    setIsRequired({
      serviceStationName: false,
      serviceStationChannel: false,
      serviceStationRegion: false,
      serviceStationCode: false,
      serviceStationContact: false,
      serviceStationLocation: false,
      organizationName: false,
    });

    setShowCancelDialog(true);
  };

  const handleCancelDialogClose = () => {
    setShowCancelDialog(false);
  };

  const handleCancelConfirm = () => {
    navigate("/home"); // Navigate to the home page
  };

  return (
    <div className="body-pd secondary-color">
      <div className="container-fluid px-0 mx-0">
        <div className="container-fluid mx-0 px-0">
          <ToastContainer />
          {/* <div className="row d-flex px-0 mx-0"> */}
          <div className="col-md-12 px-0">
            <div className="row px-0 mx-0">
              <div className="col-md-12 px-0">
                {/* Header Section Start */}
                <Header />
                {/* Header Section End */}
              </div>
            </div>

            {/* <div className="row px-1 mx-0" style={{ marginTop: "20px" }}>
              <div style={{ filter: isLoading ? "blur(5px)" : "none" }}>
                <div className="container-fluid px-0 mx-0">
                  <div className="container-fluid mx-0 px-0">
                    <ToastContainer />
                    <div className="row d-flex px-0 mx-0"> */}
            <div className="row px-1 mx-0" style={{ marginTop: "20px" }}>
              <div style={{ filter: isLoading ? "blur(5px)" : "none" }}>
                <div className="row justify-content-center">
                  <div className="col-md-10">
                    <div className="card">
                      {/* <div className="col-md-10"> */}
                      <div className="row p-2 m-2">
                        <div className="d-flex justify-content-between align-items-center mb-1">
                          <h5 className="text-center" style={{ flex: 2 }}>
                            Bulk Create Service Station
                          </h5>
                          <Button
                            variant="primary"
                            onClick={toSingleCreateServiceStation}
                          >
                            Single Create
                          </Button>
                        </div>

                        <ToastContainer />
                        {/* <div
                          className="row p-2 m-2"
                          style={{
                            borderRadius: "8px",
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                          }}
                        > */}
                        {/* Column Start */}
                        {/* <div className="row p-2 m-2"> */}
                        <div className="row p-1 m-2">
                          <div className="col-md-2 px-0 d-flex justify-content-end align-items-center"></div>
                          <div className="col-md-4 px-0 d-flex justify-content-start align-items-center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <h5 style={{ margin: "0 10px 0 0" }}>
                                Organization Name:
                              </h5>
                              <select
                                className="select-text"
                                name="organizationName"
                                onChange={handleInputChange}
                                style={{ padding: "5px" }}
                                required
                              >
                                <option value="">Select OU</option>
                                {ouOptions.map((ou, index) => (
                                  <option key={index} value={ou.id}>
                                    {ou.ouName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          {/* Choose CSV File Label */}
                          <div className="col-md-5 px-0 d-flex justify-content-end align-items-center">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginLeft: "10px",
                              }}
                            >
                              <h5 style={{ margin: "0 5px 0 0" }}>
                                Choose CSV:
                              </h5>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <input
                                  type="file"
                                  accept=".csv"
                                  onChange={handleFileUpload}
                                  style={{ padding: "5px" }}
                                />
                                {/* {isCsvInvalid && (
                                  <p
                                    style={{
                                      color: "red",
                                      margin: "5px 0 5px 0",
                                    }}
                                  >
                                    Invalid CSV File Format.
                                  </p>
                                )} */}
                                {isCsvInvalid && (
                                  <p
                                    style={{
                                      color: "red",
                                      margin: "5px 0 5px 0",
                                    }}
                                  >
                                    Invalid CSV File Format.
                                  </p>
                                )}
                                {buNameError && (
                                  <p
                                    style={{
                                      color: "red",
                                      margin: "5px 0 5px 0",
                                    }}
                                  >
                                    {buNameError}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 px-0 d-flex justify-content-end align-items-center"></div>
                        </div>

                        {/* Second Row */}
                        {fileUploaded && (
                          <div
                            className="col-md-12 mt-2 px-0"
                            style={{
                              maxHeight: "350px",
                              overflowY: "auto",
                            }}
                          >
                            <table>
                              <thead>
                                <tr>
                                  {fileUploaded && (
                                    <th style={{ width: "10%" }}>Sr. No</th>
                                  )}
                                  {csvData[0] &&
                                    Object.keys(csvData[0]).map(
                                      (key, index) => <th key={index}>{key}</th>
                                    )}
                                </tr>
                              </thead>
                              {/* <tbody>
                                {csvData.length > 0 &&
                                  csvData.map((row, rowIndex) => (
                                    <tr
                                      key={rowIndex}
                                      style={{
                                        backgroundColor:
                                          Object.keys(row).length !== 6
                                            ? "#D95336"
                                            : "transparent",
                                      }}
                                    >
                                      <td style={{ width: "10%" }}>
                                        {rowIndex + 1}
                                      </td>{" "} */}
                              {/* Display the serial number */}
                              {/* {Object.values(row).map(
                                        (value, index) => (
                                          <td key={index}>{value}</td>
                                        )
                                      )}
                                    </tr>
                                  ))}
                              </tbody> */}
                              <tbody>
                                {csvData.length > 0 &&
                                  csvData.map((row, rowIndex) => (
                                    <tr
                                      key={rowIndex}
                                      style={{
                                        backgroundColor:
                                          invalidRowIndices.includes(rowIndex)
                                            ? "#D95336"
                                            : "transparent", // Highlight all invalid rows
                                      }}
                                    >
                                      <td style={{ width: "10%" }}>
                                        {rowIndex + 1}
                                      </td>
                                      {Object.values(row).map(
                                        (value, index) => (
                                          <td key={index}>{value}</td>
                                        )
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                            {/* </div> */}
                          </div>
                        )}

                        {/* Third Row */}
                        <div className="col-md-12 mt-3 d-flex justify-content-center">
                          <Button
                            className="mx-2 btn btn-primary"
                            onClick={handleAddClick}
                            disabled={
                              !csvData.length ||
                              !isReadyToAdd ||
                              isLoading ||
                              isCsvInvalid
                            }
                          >
                            Add
                          </Button>
                          {/* Confirm Dialog */}
                          <Modal
                            show={showConfirmDialog}
                            onHide={() => setShowConfirmDialog(false)}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Confirm </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure you want to proceed with the data
                              insertion?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleCancelDilog}
                              >
                                Cancel
                              </Button>
                              <Button variant="primary" onClick={handleConfirm}>
                                Confirm
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          {/* success Dialog */}

                          {/* <Modal show={show} onHide={handleCancelSuccessModal}> */}
                          <Modal show={show}>
                            <Modal.Header>
                              <Modal.Title>
                                {newlyAddedCount > 0 ? (
                                  <b>Success</b>
                                ) : (
                                  <b>Alert</b>
                                )}
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <p>Newly Added Count: {newlyAddedCount}</p>
                              <p>
                                Existing BuCodes Count: {existingBuCodesCount}
                              </p>
                              <div>
                                <h4>Existing BU Codes</h4>
                                <div
                                  style={{
                                    maxHeight: "200px",
                                    overflow: "auto",
                                  }}
                                >
                                  {" "}
                                  {/* Adjust maxHeight as needed */}
                                  <ol>
                                    {Object.entries(existingBuCodes).map(
                                      ([key, value]) => (
                                        <li key={key}>{value}</li>
                                      )
                                    )}
                                  </ol>
                                </div>
                              </div>

                              <div style={{ color: "green" }}>
                                {newlyAddedCount > 0 ? (
                                  <div>
                                    {newlyAddedCount == 1 ? (
                                      <b>
                                        {newlyAddedCount} Service station added
                                        successfully...!
                                      </b>
                                    ) : (
                                      <b>
                                        {newlyAddedCount} Service stations added
                                        successfully...!
                                      </b>
                                    )}
                                  </div>
                                ) : (
                                  <p></p>
                                )}
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={handleCancelSuccessModal}
                              >
                                Close
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          {/* Cancel Confirmation Modal */}
                          <Modal
                            show={showCancelDialog}
                            onHide={handleCancelDialogClose}
                          >
                            <Modal.Header closeButton>
                              <Modal.Title>Confirmation</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              Are you sure you want to cancel?
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="primary"
                                onClick={handleCancelConfirm}
                                style={{
                                  backgroundColor: "red",
                                  borderColor: "red",
                                }}
                              >
                                Yes
                              </Button>
                              <Button
                                variant="secondary"
                                onClick={handleCancelDialogClose}
                              >
                                No
                              </Button>
                            </Modal.Footer>
                          </Modal>

                          <Button
                            variant="secondary"
                            // onClick={handleCancel}
                            onClick={handleCancelClick}
                            disabled={isLoading}
                          >
                            Cancel
                          </Button>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Loader spinner */}
              {isLoading && (
                <div
                  className="loading-spinner"
                  style={{ textAlign: "center", paddingTop: "20px" }}
                >
                  <ClipLoader color="#007bff" loading={isLoading} size={50} />
                  <p
                    style={{
                      backgroundColor: "#007bff",
                      color: "white",
                      borderRadius: "5px",
                      padding: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {/* Adding Data ({newlyAddedIterationCount}/{csvTotalBuCount}) */}
                    Please Wait..
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BulkUploadServiceStation;
